
import React from "react";
import {
    Container,
    Grid,
    ListItemText,
    Paper,
    Box,
    Typography,
    Button,
    useMediaQuery,
    useTheme,
    CircularProgress
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {MdDateRange} from 'react-icons/md'
import {BiRupee} from 'react-icons/bi'
import { Api } from "../../process/api";
import COLORS from "../../consts/colors";
import sorry from '../../assets/sorry.png'
import { useHistory } from "react-router";
import ReportView from "./reportView";
import SnakBarNew from '../../consts/notification'
import ReactToPrint from 'react-to-print';
import ToPrint from "./reportPrint";
import {CgReorder} from 'react-icons/cg'

export default function MyAppointments(){
    const componentRef = React.useRef();

    const history = useHistory()
    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [Data,setData] = React.useState([])
    const [Loading, setLoading] = React.useState(false)
    const [massg, setMassg] = React.useState({})
    const [report, setReport] = React.useState(null)
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'myAppointments&email='+localStorage.getItem('email'))
        .then(res=>res.json())
        .then(data=>{
            console.log(data)
            setData(data)
            setLoading(false)
        })
        .catch(err=>{
            setLoading(false)
            setMassg({
                open : true,
                massg : 'Faild to connect to server',
                severity : 'error'
            })
        })

        const check = localStorage.getItem('regitered')
        if(!check){
            history.push('/SignIn')
        }
    },[])


    const handleReportView = ()=>{
        setMassg({
            open : true,
            massg : 'Report Not generated ',
            severity : 'error'
        })
    }

    const handleReport = (item)=>{
        setReport(item)
    }
   
    return(
        <Container maxWidth={isMobile ? 'lg' : 'sm'}>
        {
        Loading ? (
            <div style={{display : 'flex',justifyContent : 'center'}}>
                <CircularProgress style={{marginTop : 50}} />
            </div>
        ) : (
            <div>
            {
                Data.length < 1 ? (
                    <div style={{display : 'flex',flexDirection : 'column',justifyContent : 'center'}}>
                        <img src={sorry} width={300} />
                        <h2>You have no appointments</h2>
                    </div>
                ) : (
                    Data.map((item,index)=>{
                        return(
                            <Paper key={index} className={classes.paper}>
                                <Box className={classes.leftBox}>
                                    <Box style={{width: '70%'}}>
                                    <h4 style={{margin: 0,marginBottom:15}}>{item.name}</h4>
                                        <div style={{display:'flex',alignItems:'center',color: COLORS.lightBlack}}>
                                            <MdDateRange style={{marginRight: 10}} size={20} />
                                            <Typography>{item.appoint_for}</Typography>
                                        </div>
                                        <div style={{display:'flex',alignItems:'center',color: COLORS.lightBlack}}>
                                            <CgReorder style={{marginRight: 10}} size={20} />
                                            <Typography>Appointment Id: {item.id}</Typography>
                                        </div>
                                        <div style={{display:'flex',alignItems:'center',color: COLORS.lightBlack}}>
                                            <BiRupee style={{marginRight: 10}} size={20} />
                                            <Typography>{item.price}</Typography>
                                        </div>
                                        {
                                            item.report != null ? (
                                                <Box style={{display :'flex',marginTop: 10}}>
                                                    <ReportView content={item.report} />
                                                    <ReactToPrint
                                                        trigger={() => <Button >Print</Button>}
                                                        content={() => componentRef.current}
                                                        onBeforeGetContent={()=>handleReport(item)}
                                                    
                                                    />
                                                    
                                                </Box>
                                            ) : (
                                                <Box style={{display :'flex',marginTop: 10}}>
                                                    <Button onClick={handleReportView}>
                                                        view
                                                    </Button>
                                                    <Button onClick={handleReportView}>
                                                        print
                                                    </Button>
                                                </Box>
                                            )
                                        }
                                         
                                    </Box>
                                        <Typography color={COLORS.orange}>{item.status}</Typography>
                
                                </Box>
                                        <Box className={classes.rightBox}>
                                            <div className={classes.tokenNo}>{item.token_no}</div>
                                            <div className={classes.token}>is your token Number</div>
                                        </Box>
                            </Paper>
                        )
                    })
                )
            }
           
           {
               report !=null ? (
                <div style={{display : 'none'}}  >
                    <ToPrint ref={componentRef} data={report} />
                </div>
               ) : ''
           }
                   
               
            </div>
        )
        }

        <SnakBarNew setMassg={setMassg} massg={massg} />

        </Container>
    )
}

const useStyle = makeStyles({
    heading : {
        fontFamily : 'Raleway',
        paddingLeft : 20,
        fontSize : 26
    },
    leftBox : {
        width : '70%',
        padding: 10,
        display : "flex"
    },
    rightBox : {
        width : '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.primary,
        borderTopRightRadius : 10,
        borderBottomRightRadius: 10,
        padding: 10

    },
    paper : {
        display:'flex',
        borderRadius: 10,
        marginTop :15
        
    },
    tokenNo : {
        fontSize : 60,
        color: COLORS.white
    },
    token : {
        fontSize : 14,
        color: COLORS.white
    }
})


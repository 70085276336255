const api_key = "ef7eb42d186239ac3a50602e0a9f9f38"
// const Api = "http://192.168.43.253/herosis/?api="+api_key+"&q="
// const ImageApi = "http://192.168.43.253/herosis/uploads/"

const Api = "https://heroasis.in/api/?api="+api_key+"&q="
const ImageApi = "https://heroasis.in/api/uploads/"
export  {ImageApi,Api};
// const api_key = "ef7eb42d186239ac3a50602e0a9f9f38"
// const Api = "https://aamarbazaar.com/her_osis/api/?api="+api_key+"&q="
// const ImageApi = "https://aamarbazaar.com/her_osis/api/uploads/"
// export  {ImageApi,Api};





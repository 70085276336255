import React from 'react'
import { Container,Grid,Paper,Avatar, Typography, ListItemText,ListItem, ListItemIcon,Box,CardContent,CardMedia,Card,CardActions,Button,useTheme,useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import COLORS from "../consts/colors";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import {Api, ImageApi} from '../process/api';
import Zoom from 'react-reveal/Zoom'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
 



 function Physiologist (props,ref){
    const [open, setOpen] = React.useState(false);
    const [slected, setSelected] = React.useState({})
    const [imgView, setImageView] = React.useState(null)
    const [readHeight, setReadHeight] = React.useState(null)
    const classes = usetStyle()
    const history = useHistory()
    const [Data,setData] = React.useState([])
    const [isVisible, setVisible] = React.useState(false);
    const handleClickOpen = (item) => {
        setSelected(item)
        setOpen(true);
       setImageView(item.img)
    };
    const handleClose = () => {
        setOpen(false);
        setImageView(null)
    };

    const selectPhys = (item)=> {
        console.log(item)
        history.push({pathname: '/Appointment',state : item})
    }
    const onRead = (index,height)=>{
        Data[index] = {
            ...Data[index],
            height : height === 800 ? 60 : 800
        }
        

        setData([...Data])
        
    }

    React.useEffect(()=>{
        console.log(ref)
        fetch(Api+'Physician')
        .then(Response=>Response.json())
        .then(data=>setData(data))
    },[])

    // React.useEffect(()=>{
    //     if(props.refs === 'Ambassadors'){
    //         refs.current.scrollIntoView({behavior : 'smooth'})
    //     }
    // },[props.refs,Data])
    const animEffect = ['left','right','bottom','top']
    
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const Modal = ()=> {

        return (
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <ListItem style={{margin: 0,padding: 0}}>
                        <ListItemIcon  >
                            <Avatar className={classes.avatar} src={ImageApi + slected.img}  />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography variant="h6" className={classes.name} >{slected.name}</Typography>}
                            secondary={ <Typography className={classes.dep}>{slected.department}</Typography>}
                        />
                    </ListItem>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                {
                    imgView != null ? (
                        <img src={imgView} width={'100%'} />
                    ) : ''
                }
                <Typography gutterBottom>
                    {slected.about}
                </Typography>
                
                </DialogContent>
                <DialogActions>
                <Button  variant="contained" className={classes.btbContained} onClick={()=>selectPhys(slected)}>
                    Make an appointment
                </Button>
                </DialogActions>
            </BootstrapDialog>
        )
    }

    return (
        <Container ref={ref != null ? ref : undefined}  maxWidth="xl" style={{backgroundColor : COLORS.primary,paddingTop : 5,paddingBottom: 5}}  disableGutters  >
            {/* <div style={{paddingTop: 20,display: 'flex',color : '#ebebeb'}}><div style={{fontSize: 37}}>O</div><div style={{marginTop: 20}}>UR AMBASSADORS</div></div>
            <hr style={{width : '10%',marginBottom: 30,marginRight :'90%',borderWidth: 3}} /> */}
            <Box>
            {
                Data.map((item,index)=>{
                    const effect = animEffect[Math.floor(Math.random()*animEffect.length)]
                    return(
                        <Card key={index} 
                            style={{
                                
                                margin : 5,
                                padding : 20,
                                alignItems : 'center',
                                padding : isMobile ? 15 : 120,
                                backgroundColor : COLORS.gery,
                                
                            }}
                        >
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={5}>
                                <Zoom {...{[effect] : true}}>
                                <img src={ImageApi+item.profile} width={'62%'} height={'300'} style={{marginLeft : '15%',borderTopLeftRadius: 15,borderBottomRightRadius: 15}}/>
                                </Zoom>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <Zoom {...{[effect] : true}}>
                                <div className={classes.name}>{item.name}</div>
                                <div className={classes.dep}>{item.department}</div>
                                <div  className={classes.about}>
                                    <Typography variant="body" style={{textAlign : 'justify'}} maxHeight= {!item.height ? 60 : item.height}>{item.about}</Typography>
                                </div>
                                    <div className={classes.btnDiv}>
                                    <div
                                        className={classes.btnAppoint}
                                        onClick={()=>selectPhys(item)}
                                    >
                                            appointment
                                    </div>
                                    <div
                                    className={classes.btnAppoints}
                                    onClick={()=>onRead(index,item.height)}
                                    >
                                    
                                        {
                                            item.height != 800 ? 'READ MORE' : 'READ LESS'
                                        }
                                    
                                    </div>
                                    </div>
                                </Zoom>
                            </Grid>

                        </Grid>
                            
                        </Card>
                    )
                })
            }
            </Box>
            <Modal />
        </Container>
    )
}

const usetStyle = makeStyles({
    avatar : {
        cursor : 'pointer',
        width : 200
    },
    paper : {
        padding: 0,
        paddingBottom : 10
    },
    name : {
        fontSize : 36,
        color: COLORS.primary,
        marginLeft: 10,
        fontWeight : 700
    },
    dep : {
        fontSize : 24,
        color: COLORS.primary,
        marginLeft: 10,
        fontWeight : 500
    },
    // social : {
    //     display :'flex',
    //     flexDirection : 'row',
    //     marginTop : -20,
    //     justifyContent: 'flex-end'
        
    // },
    about : {
        display : 'flex',
        overflow : 'hidden',
        margin:10,
        color: COLORS.black
    },
    btn : {
        color : COLORS.orange,
        
    },
    btns : {
        color : COLORS.primary,
        
    },
    btnDiv : {
        
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 10,
        marginTop: 70,
        
    },
    btbContained : {
        backgroundColor: COLORS.primary,
        color: COLORS.white
    },
    btnAppoint : {
        border: '1px solid #800020',
        padding: 20,
        marginRight: 30,
        color: COLORS.primary,
        textTransform :'uppercase',
        display : 'flex',
        alignItems : 'center',
        justifyContent: 'center',
        cursor : 'pointer',
        '&:hover' : {
            backgroundColor : COLORS.burgandylight
        }
    },
    btnAppoints : {
        border: '1px solid #800020',
        padding: 20,
        marginRight: 30,
        color: '#0095ff',
        textTransform :'uppercase',
        display : 'flex',
        alignItems : 'center',
        justifyContent: 'center',
        cursor : 'pointer',
        '&:hover' : {
            backgroundColor : COLORS.burgandylight
        }
    }
})

export default React.forwardRef(Physiologist)
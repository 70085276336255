import React from "react";
import { Avatar, Container,Divider,Grid, ListItem, ListItemIcon, ListItemText,Box, Typography } from "@mui/material";
import {makeStyles} from '@mui/styles'
import logo from '../../assets/logo.png'
import COLORS from "../../consts/colors";
import play from '../../assets/play.png'
import '../../../src/fonts.css'
import {MdCall,MdEmail} from 'react-icons/md'
import {FaWhatsapp,FaFacebook} from 'react-icons/fa'
import {AiFillInstagram, AiFillTwitterCircle} from 'react-icons/ai'
import randomColor from "randomcolor";
import { Link } from "react-router-dom";
import { Api } from "../../process/api";
import {GoLocation} from 'react-icons/go'
const features = [
   
    {
        head : 'Privacy Policy',
        link : '/Privicy'
    },
    {
        head : 'Terms and Conditions',
        link : '/Terms'
    },
    {
        head : 'Refund Policy',
        link : '/Refund'
    },
    {
        head : 'About Us',
        link : '/About'
    },

]

const socialHandles = [
    {
        icon : <FaFacebook size={35} style={{margin:10}} color={randomColor()} />,
        link : "https://www.facebook.com/heroasis.in"
    },
    {
        icon : <AiFillInstagram  size={35} style={{margin:10}} color={randomColor()}/>,
        link : "https://www.instagram.com/heroasis.in/"
    },
    {
        icon : <AiFillTwitterCircle size={35} style={{margin:10}} color={randomColor()} />,
        link : "https://twitter.com/HerOasis_in"
    }
]
export default function Fotter(){
    const classes = useStyle()
    const [conInfo,setContInfo] = React.useState({})
    
    React.useEffect(()=>{
        fetch(Api+'contactInfo')
        .then(response=>response.json())
        .then(data=>setContInfo(data))
    },[])
    const lists = [
        {
            head : 'Contact No',
            value : conInfo.contact_no,
            icon : <MdCall />
        },
        {
            head : 'Email',
            value : conInfo.email,
            icon : <MdEmail />
        },
        {
            head : 'Location',
            value : conInfo.address,
            icon : <GoLocation size={26} />
    
        }
    ]
    return(
        <Container disableGutters maxWidth="xl" className={classes.main}>
            <Box style={{
                backgroundColor : COLORS.primary,
                padding: 20,
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                marginBottom : 30
            }}>
                <div style={{marginRight : 20, fontSize: 28,fontWeight : 600,color: COLORS.white}} >Haven’t downloaded the app yet?</div>
                <a href="https://play.google.com/store/apps/details?id=com.heroasis.in.app.android" target="_blank" >
                <img src={play}  width={200} height={60}/>
                </a>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <Box style={{display : 'flex', flexDirection : 'column', justifyContent: 'center',alignItems: 'center'}}>
                    <div  className={classes.footerText}>Follow us on</div>
                    <hr color={COLORS.white} style={{width : '30%'}} />
                        <Box className={classes.social}>
                            {
                                socialHandles.map((item,index)=>
                                <a href={item.link} target="_blank" key={index}  
                                 style={{
                                    textDecoration : 'none',
                                    color : COLORS.white,
                                    marginTop: 5,
                                    display: 'flex',
                                    flexDirection : 'column',
                                    justifyContent : 'center'
                                    
                                    
                                }}>
                                    <div >{item.icon}</div>
                                </a>
                                    
                                )
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div  className={classes.footerText}>Contact Information</div>
                    <hr color={COLORS.white} style={{width : '50%',marginLeft : '25%'}} />
                   
                    {
                        lists.map((item,index)=>
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection : 'row',
                                color : COLORS.white,
                                marginTop: 10,
                                alignItems : 'center',
                                justifyContent : 'center',
                                textAlign: 'center'
                            }}>
                                {item.icon}

                                <div style={{marginLeft: 10}}>{item.value}</div>

                            </div>   
                        )
                    }
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div  className={classes.footerText}>Features</div>
                    <hr color={COLORS.white} style={{width : '50%',marginLeft : '25%'}} />
                    
                    {
                        features.map((item,index)=>
                            <div key={index} style={{display: 'flex',flexDirection : 'column'}}>
                                <Link to={item.link}  style={{
                                    textDecoration : 'none',
                                    color : COLORS.white,
                                    marginTop: 5,
                                    display: 'flex',
                                    flexDirection : 'column',
                                    justifyContent : 'center'
                                    
                                }}>
                                    <font style={{textAlign : 'center'}}>{item.head}</font>
                                </Link>
                            </div>   
                        )
                    }
                </Grid>
            </Grid>
            
        <Box
            style={{
                display : 'flex',
                backgroundColor: '#000',
                bottom : 0,
                marginTop : 150,
                color: '#fff',
                justifyContent : 'space-between',
                padding: 10
            }}
        >
        <p>Copyright &copy; Heroasis 2021</p>
        <p style={{textAlign : 'right'}}>
            <a href="https://otainfotech.in/" style={{textDecoration: 'none',color: '#fff'}}>Powered by oikos technology associate</a>
        </p>
        </Box>
        </Container>
    )
}

const useStyle = makeStyles(theme=>({
    main : {
        backgroundColor : '#292929',
        paddingBottom: 0,
        
        
        
        
    },
    logo : {
        width: 150,
        backgroundColor : COLORS.white,
        padding: 10,
        borderRadius: 20
    },
    appText : {
        color: COLORS.white,
        marginBottom : 10,
        fontFamily : [
            'Be Vietnam Pro', 'sans-serif'
        ].join(','),
        fontSize : 24
    },
    
    footerText : {
        color: COLORS.white,
        fontFamily : [
            'Be Vietnam Pro', 'sans-serif'
        ].join(','),
        fontWeight: '300',
        fontSize : '16px',
        textAlign : 'center'
        
    },
    Divider : {
        backgroundColor : '#a8a8a8',
        marginTop : 10
    },
    list : {
        margin: 0,
        padding: 0,
        color:  COLORS.darkWhite,
        fontFamily : [
            'Be Vietnam Pro', 'sans-serif'
        ].join(','),
    },
    secondaryText : {
        fontSize : 5,
        color: COLORS.lightGray

    },
    social : {
        display :'flex',
        flexDirection : 'row',
        marginTop : 20
        
    },
    socialIcons : {
        width: 50
    }

}))
import React from 'react'

import {
    Container,
    Divider, 
    useMediaQuery,
    useTheme,
    Button,
    TextField,
    Paper,
    LinearProgress ,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    FormControl,
    Radio
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import { Api } from '../../process/api'
import { useHistory } from 'react-router'
import SnakBarNew from '../../consts/notification'
export default function Profile(){
    const history = useHistory()
    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [editState, setEditState] = React.useState(false)
    const [Loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState({})
    const [massg, setMassg] = React.useState({})
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'userInfo&email='+localStorage.getItem('email'))
        .then(res=>res.json())
        .then(data=>{
            setLoading(false)
            setData(data)
            console.log(data)
        })
        .catch(err=>console.log(err))

        const check = localStorage.getItem('regitered')
        if(!check){
            history.push('/SignIn')
        }
    },[])

    const editStateHandle = ()=>{
        setEditState(true)
    }
    const handleChange = (e)=>{
        
        setData({
            ...data,
            [e.target.name] : e.target.value
        })

        
    }
   
    const onSubmit = ()=>{

        setLoading(true)
        fetch(Api+'updateuserInfo&email='+localStorage.getItem('email'),{
            method : 'POST',
            body : JSON.stringify(data)
        })
        .then(res=>res.json())
        .then(datas=>{
            console.log(datas)
            setLoading(false)
            if(datas.status === 201){
                localStorage.setItem('email',data.Email)
                setMassg({
                    open : true,
                    massg : datas.massg,
                    severity : 'success'
                })
            }else{
                setMassg({
                    open : true,
                    massg : datas.massg,
                    severity : 'error'
                })
            }
        })
        .catch(err=>{
            setMassg({
                open : true,
                massg : "Faild to connect to server",
                severity : 'error'
            })
        })
    }
    return (
        <Container maxWidth={isMobile ? 'lg' : 'sm'} component={Paper} style={{marginTop: 40,padding: 15}}>
            <div style={{display : 'flex',justifyContent : 'space-between'}}>
                <div className={classes.heading}>Profile Information</div>
                {
                    editState === false ? (
                        <Button onClick={editStateHandle} variant="contained" size="small">
                            Edit
                        </Button>
                    ) : (
                        <Button onClick={onSubmit} variant="contained" size="small">
                            Save
                        </Button>
                    )
                }
                
            </div>
            <Divider />
            {
                Loading ? <LinearProgress  /> : ''
            }
            {
                Object.keys(data).map((item,index)=>{
                    return (
                        <div key={index}>
                        {
                            item === 'Gender' ? (
                                <FormControl  component="fieldset" style={{marginLeft : 5}} >
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup  row aria-label="gender"  name={item} value={data[item]}  onChange={handleChange}>
                                        <FormControlLabel disabled={!editState ? true : false} value="female" control={<Radio />} label="Female" />
                                        <FormControlLabel disabled={!editState ? true : false} value="male" control={<Radio />} label="Male" />
                                        <FormControlLabel disabled={!editState ? true : false} value="other" control={<Radio />} label="Other" />
                                    </RadioGroup>
                                </FormControl>
                            ) : (
                                <TextField
                                    label={item}
                                    variant="outlined"
                                    value={data[item]}
                                    disabled={!editState ? true : false}
                                    fullWidth
                                    style={{
                                        marginTop : 10
                                    }}
                                    name={item}
                                    onChange={handleChange}
                                />
                            )
                        }
                            
                        
                        </div>
                    )
                })
            }
            <SnakBarNew setMassg={setMassg} massg={massg} />
        </Container>
    )
}

const useStyle = makeStyles({
    heading : {
        fontFamily : 'Raleway',
        paddingLeft : 20,
        fontSize : 26,
        fontWeight : 700,
        textAlign : 'center',
        margin: 10
    },
})
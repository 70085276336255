import React from "react";
import { Container,Alert,Button,useTheme,useMediaQuery, Paper,Grid,Box, Tooltip,IconButton, Toolbar, Typography, LinearProgress  } from "@mui/material";
import Physiologist from "../physiologists";
import WhyChoose from "../sub_home/whyChosee";
import Reviews from "../sub_home/reviews";
import {makeStyles} from '@mui/styles'
import { useHistory } from "react-router";
import { Api } from "../../process/api";
import AppointImage from '../../assets/appointments.png'
import ProfileImage from '../../assets/profile.png'
import COLORS from "../../consts/colors";
import { Link } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Divider } from "@material-ui/core";
export default function Dashboard(){
    const history = useHistory()
    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [emailStatus, setEmailStatus] = React.useState(true)
    const [tokenInfo, setTokenInfo] = React.useState([])
    const [refresh, setRefresh] = React.useState(Math.random())
    const [refreshing, setRefreshing] = React.useState(false)
    React.useEffect(()=>{
        const check = localStorage.getItem('regitered')
        if(!check){
            history.push('/SignIn')
        }else{
            fetch(Api+'emailVarified&email='+localStorage.getItem('email'))
            .then(Response=>Response.json())
            .then(data=>setEmailStatus(data))

            
        }
        
    },[])

    React.useEffect(()=>{
        setRefreshing(true)
            fetch(Api+'tokenInfo&email='+localStorage.getItem('email'))
            .then(res=>res.json())
            .then(data=>{
                setTokenInfo(data)
                setRefreshing(false)
            })
    },[refresh])
    return (
        <Container maxWidth="xl" >
            {
                !emailStatus ? (
                    <Alert className={classes.Alert} severity="error">
                        Your email is not verified yet!
                    </Alert>
                ) : ''
            }
            {
                 tokenInfo.length > 0 ? (
                    <Container
                        maxWidth="sm"
                        style={{
                            border: '1px solid #e5e5e5',
                            borderRadius: 5
                        }}
                    >
                                <Toolbar style={{justifyContent : 'space-between'}}>
                                    <h3>Current Token</h3>
                                    <Paper>
                                    <IconButton onClick={()=>setRefresh(Math.random())}>
                                        <Tooltip title="refresh">
                                            <RefreshIcon />
                                        </Tooltip>
                                    </IconButton>
                                    </Paper>
                                </Toolbar>
                                <Divider />
                                {
                                    refreshing ? <LinearProgress style={{color : COLORS.primary}} /> : ''
                                }
                            
                            
                                    <Typography variant="h1" style={{textAlign : 'center',color: COLORS.primary}}>
                                    {tokenInfo[0].token_no}
                                    </Typography>
                            
                            
                    </Container>

                 ) : ''
            }
             
            <Grid container spacing={1}>
                <Grid item xs={12}  sm={6} style={{display : 'flex',marginTop: 20,paddingBottom : 15}}>
                    <Box style={{width: isMobile ? '45%' : '35%'}}>
                        <img src={AppointImage} width={'100%'} />
                    </Box>
                   
                    <Box>
                        <h3
                            style={{marginBottom:10}}
                        >
                            My Appointments
                        </h3>
                        <Typography
                            style={{
                                fontSize: 16,
                                color : COLORS.lightBlack,
                                marginBottom : 20
                            }}
                        >
                            You can manage all your appointments from here
                        </Typography>
                        <Link to="/MyAppointments" style={{textDecoration : 'none'}}>
                        <Button
                            variant="contained"
                            style={{backgroundColor : COLORS.primary}}
                        >
                            My Appointments
                        </Button>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12}  sm={6} style={{display : 'flex',marginTop: 20,paddingBottom : 15}}>
                    <Box style={{width: isMobile ? '45%' : '35%'}}>
                        <img src={ProfileImage} width={'100%'} />
                    </Box>
                    <Box>
                        <h3
                            style={{marginBottom:10}}
                        >
                            My Profile
                        </h3>
                        <Typography
                            style={{
                                fontSize: 16,
                                color : COLORS.lightBlack,
                                marginBottom : 20
                            }}
                        >
                            You can manage your profile information from here
                        </Typography>
                        <Link to="/Profile" style={{textDecoration : 'none'}}>
                        <Button
                            variant="contained"
                            style={{backgroundColor : COLORS.primary}}
                        >
                            My Profile
                        </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            
            <Physiologist />
            {/* <WhyChoose /> */}
            {/* <Reviews /> */}
        </Container>
    )
}

const useStyle = makeStyles({
    Alert : {
        margin: 20,
        display: 'flex',
        justifyContent : 'center',
        alignItems : 'center'
    }
})
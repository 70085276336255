import React from 'react'
import { Avatar } from '@material-ui/core'
import {
    Container,
    Box
} from '@mui/material'
import {MdDateRange,MdPayment} from 'react-icons/md'
import {BsPencilSquare} from 'react-icons/bs'
import COLORS from '../../consts/colors'
import '../../fonts.css'
import '../../consts/styles/animation.css'
export default function Steps(){
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        // return () => observer.unobserve(domRef.current);
    }, []);

    return(
        <Container className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef} disableGutters maxWidth="xl" style={{backgroundColor : COLORS.burgandylight,padding: 20,borderRadius: 5,marginTop : 100}}>
            <div style={{
                textAlign: 'center',
                color: COLORS.black,
                fontSize:18,
                fontWeight : 600,
                fontFamily : 'Raleway',
                marginBottom : 10

            }}
            >
                Steps for appointment
            </div>
            <Box style={{
                color: COLORS.primary,
                display : 'flex',
                justifyContent: 'center',
                alignItems : 'center'
            }}>
                <div style={{display: 'flex',flexDirection:'column',justifyContent:'center',alignItems: 'center',textAlign:'center'}}>
                    <Avatar style={{backgroundColor : COLORS.primary}}>
                        <MdDateRange />
                    </Avatar>
                    Select Date
                </div>
                <div style={{width: '30%',marginTop: -20,marginLeft: 10}}>
                <hr color={COLORS.white} />
                </div>
                <div style={{display: 'flex',flexDirection:'column',justifyContent:'center',alignItems: 'center',textAlign:'center'}}>
                    <Avatar style={{backgroundColor : COLORS.primary}}>
                        <BsPencilSquare />
                    </Avatar>
                    Note Problem
                </div>
                <div style={{width: '30%',marginTop: -20}}>
                <hr color={COLORS.white} />
                </div>
                <div style={{display: 'flex',flexDirection:'column',justifyContent:'center',alignItems: 'center',textAlign:'center'}}>
                    <Avatar style={{backgroundColor : COLORS.primary}}>
                        <MdPayment />
                    </Avatar>
                    Make Payment
                </div>
                
            </Box>
        </Container>
    )
}
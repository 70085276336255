import React from 'react'
import {
    Container,
    Paper,
    Grid,
    Typography,
    FormControl,
    TextField,
    Box,
    Button,
    useTheme,
    useMediaQuery,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormLabel,
    InputLabel,
    Select,
    MenuItem,

} from '@mui/material'
import {makeStyles} from '@mui/styles'
import { Link } from 'react-router-dom'
import register from './../../assets/register.png'
import COLORS from '../../consts/colors'
import '../../fonts.css'
import {FaArrowRight} from 'react-icons/fa'
import {IconContext} from "react-icons";
import Loader from "react-js-loader";
import isEmail from 'validator/lib/isEmail';
import {Api} from '../../process/api'
import { useHistory } from 'react-router'
import SnakBarNew from '../../consts/notification'
export default function Register(){
    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const history = useHistory()
    const [formData, setFormData] = React.useState({})
    const [error, setError] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [Massg, setMassg] = React.useState({})
    const [a, setA] = React.useState(null)
    const handleChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })

        if(e.target.name === 'a'){
            setA(e.target.value)
        }
    }
    const validate = ()=>{
        let errors = {}
        let valid = true

        if(!formData.f_name){
            valid = false
            errors['f_name'] = true
        }
        if(!formData.l_name){
            valid = false
            errors['l_name'] = true
        }
        if(!formData.email){
            valid = false
            errors['email'] = true
            
        }
        if(!formData.ph_no){
            valid = false
            errors['ph_no'] = true
        }
        if(!formData.a){
            valid = false
            errors['a'] = true
        }
        if(!formData.a_data){
            valid = false
            errors['a_data'] = true
        }
        // if(!formData.pass){
        //     valid = false
        //     errors['pass'] = true
        // }
        // if(formData.pass != formData.c_pass){
        //     valid = false
        //     errors['c_pass'] = true
        // }
        // if(!formData.c_pass){
        //     valid = false
        //     errors['c_pass'] = true
        // }
        // // if(!formData.add){
        //     valid = false
        //     errors['add'] = true
        // }
        // if(!formData.gender){
        //     valid = false
        //     errors['gender'] = true
        // }
        if(formData.email != undefined && isEmail(formData.email)){
            errors['not_email'] = false
        }else{
            errors['not_email'] = true
            valid = false
        }

        setError(errors)

        return valid
    }

    const onSubmit =  ()=>{
       
       if (validate()){
        setLoading(true)
        setMassg({
            massg : null ,
            severty : null
        })
        fetch(Api+'userRegister',{
            method : 'POST',
            body : JSON.stringify(formData)
        })
        .then(response=>response.json())
        .then(data=>{
            setLoading(false)
            if(data.status === 201){
                //  localStorage.setItem('email', formData.email)
                //  localStorage.setItem('regitered', true)
                //  localStorage.setItem('emailVarified',false)

                //  history.push('/Home')
                setMassg({
                    massg : data.massg,
                    severity : 'success',
                    open : true
                })
            }else {
                setMassg({
                    massg : data.massg,
                    severity : 'error',
                    open : true
                })
                
            }
        })
        .catch(err=>{
            setMassg({
                massg : "Couldn't connect to the server" ,
                severity : 'error',
                open : true
            })
            setLoading(false)
        })
    }
    }
    return(
        <Container maxWidth="md"  >
            <Grid container component={Paper} elevation={1}  style={{
                backgroundColor : '#f7f7f7',
                borderRadius: 20,
            }}>
                <Grid item xs={12} sm={6}>
                    <img src={register} className={classes.img} />
                </Grid>
                <Grid item xs={12} sm={6} style={{
                     borderRadius : 20,
                     padding : theme.spacing(isMobile ? 1 : 5),
                     backgroundColor : COLORS.white
                }} >
                    <div className={classes.rightCon}>
                    <div className={classes.heading}>Register With HerOasis</div>
                    {/* <div className={classes.subHead}>
                        Already have an account? <Link to="/SignIn" style={{textDecoration : 'none'}}>Sign In</Link>
                    </div> */}
                    <Box className={classes.form}>
                        <div className={classes.multiycol}>
                            <div className={classes.TextField}>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                name="f_name"
                                type="text"
                                value={formData.f_name}
                                error={error.f_name}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                            </div>
                            <div className={classes.TextField}>
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                type="text"
                                name="l_name"
                                value={formData.l_name}
                                error={error.l_name}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                            </div>
                        </div>
                        <div className={classes.TextField}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            name="email"
                            type="email"
                            value={formData.email}
                            error={error.email}
                            onChange={handleChange}   
                            fullWidth
                            required
                            helperText={error.not_email ? <Typography style={{fontSize :12}} color="red">Please enter a valid email</Typography> : ''}
                        />
                        </div>
                        <div className={classes.TextField}>
                        <TextField
                            label="Contact No"
                            variant="outlined"
                            name="ph_no"
                            type="number"
                            value={formData.ph_no}
                            error={error.ph_no}
                            onChange={handleChange}   
                            fullWidth
                            required
                        />
                        </div>
                        {/* <div className={classes.TextField}>
                        <TextField
                            label="Address"
                            variant="outlined"
                            name="add"
                            type="text"
                            value={formData.add}
                            error={error.add}
                            onChange={handleChange}   
                            fullWidth
                            required
                        />
                        </div>
                        <FormControl error={error.gender} component="fieldset" style={{marginLeft : 5}} >
                            <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup row aria-label="gender"  name="gender"  onChange={handleChange}>
                                <FormControlLabel value="female" control={<Radio />} label="Female" />
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                            </RadioGroup>
                        </FormControl>
                        <div className={classes.multiycol}>
                        <div className={classes.TextField}>
                        <TextField
                            label="Password"
                            variant="outlined"
                            type="password"
                            name="pass"
                            value={formData.pass}
                            error={error.pass}
                            onChange={handleChange} 
                            fullWidth
                            required
                        />
                        </div>
                        <div className={classes.TextField}>
                        <TextField
                            label="Confirm Password"
                            variant="outlined"
                            name="c_pass"
                            value={formData.c_pass}
                            error={error.c_pass}
                            onChange={handleChange} 
                            helperText={error.c_pass_text ? "Password not matched" : ''}
                            fullWidth
                            required
                        />
                        </div> */}
                        {/* </div> */}

                        <div className={classes.TextField}>
                        <FormControl variant="outlined"  fullWidth style={{margin: 5}}>
                            <InputLabel id="demo-simple-select-outlined-label">Are you a?</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                error={error.a}
                                value={formData.a}
                                onChange={handleChange}
                                // label="type"
                                name="a"
                                
                            >
                            <MenuItem value={false}>
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="Volunteer">Volunteer</MenuItem>
                            <MenuItem  value="Person In Distress"> Person In Distress</MenuItem>
                            </Select>
                        </FormControl>
                        </div>
                        {
                             a === 'Volunteer' ? (
                                <div className={classes.TextField}>
                                    <TextField
                                        helperText="Tell Us What You Would Like To Help Someone With"
                                        value={formData.a_data}
                                        onChange={handleChange}
                                        name="a_data"
                                        error={error.a_data}
                                        multiline
                                        fullWidth
                                    />
                                </div>
                            ) : a ===  'Person In Distress' ? (
                                <div className={classes.TextField}>
                                    <TextField
                                        helperText="Tell Us What Your Problems and Issues Are"
                                        value={formData.a_data}
                                        onChange={handleChange}
                                        name="a_data"
                                        error={error.a_data}
                                        multiline
                                        fullWidth
                                    />
                                </div>
                            ) : ''
                        }

                        <div className={classes.TextField}>
                        <Button 
                            className={classes.btn} 
                            variant="contained" 
                            size="large"
                            fullWidth
                            onClick={onSubmit}
                            disabled={loading}
                        >   {
                            loading ? (
                                
                                    <Loader type="bubble-top" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={20} />
                               
                            ) : (
                                <IconContext.Provider value={{ color: COLORS.white, style:{marginLeft :10} }}>
                                <div>
                                    Register
                                    <FaArrowRight />
                                </div>
                                </IconContext.Provider>
                            )
                        }
                            
                            
                              
                        </Button> 
                        </div>
                        
                        
                    </Box>
                    </div>
                </Grid>
            </Grid>
            <SnakBarNew setMassg={setMassg} massg={Massg} />
        </Container>
    )
}


const useStyle = makeStyles(theme=>({
    img : {
        width: '80%',
        marginLeft: '10%'
    },
    heading : {
        fontFamily : 'Raleway',
        paddingLeft : 20,
        fontSize : 26
    },
    subHead : {
        paddingLeft : 20,
        color : COLORS.lightBlack,
        fontFamily : 'Raleway',
    },
    multiycol : {
        display : 'flex',
        flexDirection: 'row',
        
    },
    TextField : {
        marginTop: 5,
        marginBottom : 5,
        marginLeft: 5
    },
    form : {
        padding : 15,
        
    },
    btn : {
        backgroundColor: COLORS.primary,
        color: COLORS.white,
        marginLeft: 5,
        marginTop: 10,
        '&:hover' : {
            backgroundColor: COLORS.primary,
            
        }
    },
    btnIcon:{
        color: COLORS.white,
        fill : 'white'
        
    },
    rightCon : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
        height : '100%',
        borderRadius : 15,
        backgroundColor : COLORS.white
       
       
    }
}))
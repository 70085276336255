import React, { useEffect } from "react";
import { Container, Paper,Box, Avatar, Typography, Divider } from "@mui/material";
import {makeStyles} from '@mui/styles'
import Slider from "react-slick";
import COLORS from "../../consts/colors";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import randomColor from "randomcolor";
import { Api } from "../../process/api";

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    autoplay: true,
      speed: 2000,
      autoplaySpeed: 500,
      cssEase: "linear",
      lazyLoad: true,
  };
export default function Reviews(){
    const classes = useStyle()
    const [Data, setData] = React.useState([])

    useEffect(()=>{
        fetch(Api+'reviews')
        .then(res=>res.json())
        .then(data=>setData(data))
    },[])
    return (
        <Container className={classes.main}>
            {
                Data.length > 0 ? (
                <div>
                    <h2 style={{textAlign:'center',marginTop: 100}}>Client Reviews</h2>
                    <hr style={{width : '40%',marginBottom: 50}} />
                    <Slider
                    {...settings}
                    >
                

            {
                Data.map((item,index)=> 
                    <div key={index}>
                        <Box elevation={0} className={classes.paper}>
                            <Box className={classes.revBox}>
                                <Avatar style={{backgroundColor : randomColor()}} sizes="l" className={classes.profileDesign}>
                                    {item.name.substring(0,1)}
                                </Avatar>
                                <Box className={classes.revHead}>
                                    <Typography variant='h6' style={{fontWeight: 600}}>{item.name}</Typography>
                                    <Typography variant='h7'>{item.location}</Typography>
                                    
                                </Box>
                                
                            </Box>
                            
                            <Typography className={classes.review}>
                                {item.review}
                            </Typography>
                        </Box>
                    </div>
                )
            }
                
            </Slider>
            </div>
                ) : ''
            }

        </Container>
    )
}

const useStyle = makeStyles(theme=>({
    main : {
        backgroundColor : COLORS.lightGray,
        paddingBottom: 40,
        width: '98%',
    },
    paper : {
        backgroundColor : COLORS.secondary,
        borderRadius :20,
        margin: 10,
        height : 400
    },
    revBox : {
        display : 'flex',
        flexDirection : 'row',
        width: '100%',
        padding : 15
    },
    profileDesign : {
        display : 'flex',
        justifyContent:'center',
        alignItems : 'center',
        width: 60,
        height: 60,
    },
    revHead : {
        paddingLeft: 15,
    },
    review : {
        padding: 20,
        paddingTop : 0
    }
}))
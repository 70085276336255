import React from 'react'

import {
    Container,
    Box,
    Grid,
    Button
} from '@mui/material'
import { Api,ImageApi } from '../../process/api'
import { Typography } from '@material-ui/core'
import {BsBookHalf} from 'react-icons/bs'
import { IconContext } from "react-icons/lib"
import COLORS from '../../consts/colors'
import {Link} from 'react-router-dom'
 function Blogs(props,ref){
    const [Data, setData] = React.useState([])
    const [height, setHeight] = React.useState(100)
    React.useEffect(()=>{
        console.log(Api+'blogs')
        fetch(Api+'blogs')
        .then(res=>res.json())
        .then(data=>setData(data))
    },[])
    const handleHeight = (ind,getRef)=>{
        setHeight({[ind] : true}) 
    }
    return(
        <Container ref={ref != null ? ref : undefined}>
        {
            Data.length > 0 ? <h1 style={{textAlign: 'center',marginBottom: 50 }}>Blogs</h1> : ''
        }

        {
            Data.map((item,index)=>{
                return(
                    <Grid container spacing={1}
                        key={index}
                        style={{marginTop : 20}}
                        
                    >
                        <Grid
                            xs={12}
                            sm={3}
                        >
                            <img src={ImageApi+'news/'+item.image} style={{width: '90%',marginLeft : '5%'}} />
                        </Grid>
                        <Grid
                            xs={12}
                            sm={9}
                        >
                            <h2 style={{margin: 0}}>{item.head}</h2>
                            <Typography>
                                {item.publish_date}
                                {
                                    item.attachment != '' ? <a style={{marginLeft: 20}} href={ImageApi+'attachments/'+item.attachment} download target="_blank"> Download attachments </a> : ''
                                }
                                
                            </Typography>
                            <div
                                style={{
                                    maxHeight : !height[index] ? 100 : 8000,
                                    overflowY : 'hidden'
                                }}
                                dangerouslySetInnerHTML={{__html : item.content}}
                            >

                            </div>
                            <div style={{textDecoration : 'none',display : 'flex'}}>
                                                {
                                                    !height[index] ? (
                                                        <Button 
                                                            size="small"
                                                            variant="outlined" 
                                                            style={{marginTop: 20,marginLeft : 'auto',marginRight: 20,borderColor: COLORS.primary,color: COLORS.primary}}
                                                            startIcon={<IconContext.Provider  value={{ style:{marginTop :10,color: COLORS.primary} }}>
                                                            <div>
                                                                <BsBookHalf  />
                                                            </div>
                                                            </IconContext.Provider>}
                                                            onClick={()=>handleHeight(index,item.name)}
                                                            
                                                        >
                                                                Read More
                                                            
                                                        </Button>
                                                    ) : (
                                                        <Button 
                                                            size="small"
                                                            variant="outlined" 
                                                            color="primary"
                                                            style={{marginTop: 20,marginLeft : 'auto',marginRight: 20,borderColor: COLORS.primary,color: COLORS.primary}}
                                                            startIcon={<IconContext.Provider  value={{ style:{marginTop :10,color: COLORS.primary} }}>
                                                            <div>
                                                                <BsBookHalf  />
                                                            </div>
                                                            </IconContext.Provider>}
                                                            onClick={()=>setHeight(100)}
                                                        >
                                                                Read Less
                                                            
                                                        </Button>
                                                    )
                                                }

                                                
                            </div>
                        </Grid>

                    </Grid>
                )
            })
        }

        </Container>
    )
}


export default React.forwardRef(Blogs)
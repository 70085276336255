import React from "react"
import { Container,Paper,Grid,Typography,Button,useMediaQuery,useTheme, setRef } from "@mui/material"
import {makeStyles} from '@mui/styles'
import {Api, ImageApi} from "../../process/api"
import '../../fonts.css'
import {BsBookHalf} from 'react-icons/bs'
import { IconContext } from "react-icons/lib"
import '../../consts/styles/animation.css'
import COLORS from "../../consts/colors"
import '../../consts/styles/gradiants.css'
import Bounce from 'react-reveal/Bounce';
 function AboutHome({myref,setMyref}){
    const classes = useStyle()
    // const ref = React.createRef()
    const ref = React.useRef();
    const [isVisible, setVisible] = React.useState(true);
    const [data, setData] = React.useState([])
    const [height, setHeight] = React.useState(200)
    const [newRef, setNewRef] = React.useState(myref)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    React.useEffect(()=>{
       
        fetch(Api+'ContentCol&type=about_us')
        .then(Response=>Response.json())
        .then(data=>setData(data))
    },[])

    React.useEffect(()=>{
        setNewRef(myref)
       if(ref.current != null){
            ref.current.scrollIntoView({behavior : 'smooth',block : 'end'})
       }

       
    })
 
    const animEffect = ['left','right','bottom','top']

   
    const handleHeight = (ind,getRef)=>{
        setHeight({[ind] : true}) 
      
    //    handleRef()
    }
    const handleRef = (e)=>{
        if(e===newRef){
             
             return ref
        }else{
            return ref.current = null
        }
        
    }
   
    return (
        <Container  style={{paddingBottom : 20,padding: 0,paddingTop: 0,margin:0,backgroundAttachment : 'fixed' }} maxWidth="xl" disableGutters   className={classes.help}>
        <Paper  elevation={0}>
                    {
                        data.map((item,index)=>{
                            const effect = animEffect[Math.floor(Math.random()*animEffect.length)]
                            
                            return (
                                <div key={index} style={{marginBottom : item.priority_value  > 999 ? -120 : 0}}>
                                {
                                    index % 2 === 0 ? (
                                        <Grid ref={handleRef(item.name)} style={{display : 'flex',justifyContent: 'center',alignItems : 'center',paddingTop : 150,paddingBottom: 150,paddingLeft: isMobile ? 20 : 150,paddingRight: isMobile ? 20 : 150 }} container key={index} className={'gradiant'}  spacing={0}>
                                    
                                            <Grid item xs={12} style={{mixBlendMode : item.priority_value != 999 ? 'multiply' : 'normal'}} sm={5} >
                                                <Bounce {...{[effect] : true}}>
                                                    <div >
                                                    <img src={ImageApi+'contents/'+item.image} className={classes.OnlineHelp} />
                                                    </div>
                                                </Bounce>
                                            </Grid>
                                            <Grid  item xs={12}  sm={7}>
                                                <Bounce {...{[effect] : true}}>
                                                <div className={classes.onlineText} style={{maxHeight : !height[index] ? 200 : 8000}} dangerouslySetInnerHTML={{__html: item.content}}>
                                                
                                                </div> 
                                                </Bounce>
                                                <div style={{textDecoration : 'none',display : 'flex'}}>
                                                {
                                                    !height[index] ? (
                                                        <Button 
                                                            size="small"
                                                            variant="outlined" 
                                                            style={{marginTop: 20,marginLeft : 'auto',marginRight: 20,borderColor: COLORS.white,color: COLORS.white}}
                                                            startIcon={<IconContext.Provider  value={{ style:{marginTop :10,color: COLORS.white} }}>
                                                            <div>
                                                                <BsBookHalf  />
                                                            </div>
                                                            </IconContext.Provider>}
                                                            onClick={()=>handleHeight(index,item.name)}
                                                            
                                                        >
                                                                Read More
                                                            
                                                        </Button>
                                                    ) : (
                                                        <Button 
                                                            size="small"
                                                            variant="outlined" 
                                                            color="primary"
                                                            style={{marginTop: 20,marginLeft : 'auto',marginRight: 20,borderColor: COLORS.white,color: COLORS.white}}
                                                            startIcon={<IconContext.Provider  value={{ style:{marginTop :10,color: COLORS.white} }}>
                                                            <div>
                                                                <BsBookHalf  />
                                                            </div>
                                                            </IconContext.Provider>}
                                                            onClick={()=>setHeight(100)}
                                                        >
                                                                Read Less
                                                            
                                                        </Button>
                                                    )
                                                }
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid ref={handleRef(item.name)} container key={index} style={{display : 'flex',justifyContent: 'center',alignItems : 'center',paddingTop : 150,paddingBottom: 150,paddingLeft: isMobile ? 20 : 150,paddingRight: isMobile ? 20 : 150 }} className={'gradiant-even'} spacing={0}>
                                    
                                            <Grid item xs={12} sm={5} style={{mixBlendMode : item.priority_value != 999 ? 'multiply' : 'normal'}}>
                                                <Bounce {...{[effect] : true}}>
                                                    <img src={ImageApi+'contents/'+item.image} className={classes.OnlineHelp} />
                                                </Bounce>
                                            </Grid>
                                            <Grid item xs={12}  sm={7}>
                                                
                                                <Bounce {...{[effect] : true}}>
                                                <div className={classes.onlineText} style={{maxHeight : !height[index] ? 200 : 8000}} dangerouslySetInnerHTML={{__html: item.content}}>
                                                
                                                </div> 
                                                </Bounce>
                                                <div style={{textDecoration : 'none',display : 'flex'}}>
                                                {
                                                    !height[index] ? (
                                                        <Button 
                                                            size="small"
                                                            variant="outlined" 
                                                            color="primary"
                                                            style={{marginTop: 20,marginLeft : 'auto',marginRight: 20,borderColor: COLORS.white,color: COLORS.white}}
                                                            startIcon={<IconContext.Provider  value={{ style:{marginTop :10,color: COLORS.white} }}>
                                                            <div>
                                                                <BsBookHalf  />
                                                            </div>
                                                            </IconContext.Provider>}
                                                            onClick={()=>handleHeight(index,item.name)}
                                                        >
                                                                Read More
                                                            
                                                        </Button>
                                                    ) : (
                                                        <Button 
                                                            size="small"
                                                            variant="outlined" 
                                                            color="primary"
                                                            style={{marginTop: 20,marginLeft : 'auto',marginRight: 20,borderColor: COLORS.white,color: COLORS.white}}
                                                            startIcon={<IconContext.Provider  value={{ style:{marginTop :10,color: COLORS.white} }}>
                                                            <div>
                                                                <BsBookHalf  />
                                                            </div>
                                                            </IconContext.Provider>}
                                                            onClick={()=>setHeight(100)}
                                                        >
                                                                Read Less
                                                            
                                                        </Button>
                                                    )
                                                }
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )

                                    
                                }

                                    </div>              

                                
                            )
                        })
                    }
                    
                </Paper>
                
        </Container>
    )
}

const useStyle = makeStyles({

    help : {
        
        marginBottom:0,
        margin: 0,
        marginTop: 40,
       
        
    },
    OnlineHelp : {
        width: '80%',
        borderRadius: 10,
        marginLeft: '10%'
        
    },
    
    onlineText : {
        margin : 0,
        padding: 20,
        textOverflow : 'ellipsis',
        overflowY: 'hidden',
        
        WebkitLineClamp : 2,
        WebkitBoxOrient : 'verticald',
        
       
    },






})

export default React.forwardRef(AboutHome)
import { Container } from '@mui/material'
import React from 'react'
import { Api } from '../../process/api'


export default function Privicy(){
    const [data,setData] = React.useState({})
    React.useEffect(()=>{
        fetch(Api+'privicy')
        .then(response=>response.json())
        .then(data=>setData(data))
    },[])
    return (
        <Container>
            <div dangerouslySetInnerHTML={{__html: data.content}}>

            </div>
        </Container>
    )
}
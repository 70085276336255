import React from "react";
import { Container,Typography,Box,Toolbar } from "@mui/material";
import logo from '../../assets/logo.png'


class ToPrint extends React.Component{
    
    render(){
        const data = this.props.data
        return (
            <Container maxWidth="lg">
                <Toolbar style={{justifyContent: 'space-between'}}>
                <Box>
                    <img src={logo} width="100" />
                    <h2>HEROASIS</h2>
                    <Typography>Email: support@heroasis.in</Typography>
                    <Typography>{data.name}</Typography>
                    <Typography>{data.department}</Typography>
                </Box>
                <Box>
                    <Typography>Booking Id: {data.id}</Typography>
                    <Typography>Payment Status: {data.payment_status}</Typography>
                    <Typography>Visiting Charge: {data.price}</Typography>
                    <Typography>Token No: {data.token_no}</Typography>
                    <Typography>Booking Status: {data.status}</Typography>
                    <Typography>Appointment date: {data.appoint_for}</Typography>
                </Box>
                </Toolbar>
                <hr style={{width : '100%'}} />
                <Typography>Subject : {data.subject}</Typography>
                <Typography>Problem : {data.problems}</Typography>
                <h3>Report:</h3>
                <div dangerouslySetInnerHTML={{__html: data.report}}>

                </div>
            </Container>
        )
    }
}

export default ToPrint
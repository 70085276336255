import React, { useState } from 'react';
import {
    Container,
    Paper,
    Grid,
    Typography,
    FormControl,
    TextField,
    Box,
    Button,
    useTheme,
    useMediaQuery,
    ListItem,
    ListItemIcon,
    ListItemText,
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle
    
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import { Link,useHistory } from 'react-router-dom'
import calender from '../../assets/calender.png'
import COLORS from '../../consts/colors'
import '../../fonts.css'
import {FaArrowRight,FaCalendarAlt} from 'react-icons/fa'
import {IconContext} from "react-icons";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Alert from '@mui/material/Alert';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import {RiSecurePaymentFill,RiRefundFill} from 'react-icons/ri'
import {BiRupee} from 'react-icons/bi'
import {MdSentimentSatisfied,MdBlock} from 'react-icons/md'
import Physiologist from '../physiologists';
import ligma from '../../assets/physylogist/ligma.jpg'
import {RiExchangeBoxFill} from 'react-icons/ri'
import { Divider, Toolbar } from '@material-ui/core';
import SignIn from '../login/signin';
import SignUp from '../login/signup';
import { Api, ImageApi } from '../../process/api';
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import Loader from 'react-js-loader'
import logo from '../../assets/logo.png'
import Checkbox from '@mui/material/Checkbox';
import SnakBarNew from '../../consts/notification';
import InformedConsent from './informed_consent';
const steps = ['Set slot', 'Information', 'Checkout'];
const paySure = [
    {
        head : '100% customer satisfaction',
        icon : <MdSentimentSatisfied />
    },
    {
        head : 'No hidden charge applicable',
        icon : <MdBlock />
    },
    {
        head : 'Easy refund policy',
        icon : <RiRefundFill />
    }
]

export default function Appointment(props){
    const history = useHistory()
    const Razorpay = useRazorpay()
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [setedDoctors, setSetedDoctors] = React.useState({display : false,content : null})
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selected, setSelected] = useState({})
    const [modal,setModal] = useState(false)
    const [slotInfo, setSlotInfo]= useState({totalSlot: 0, usedSlot : 0})
    const [userProble, setUserProblem] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const today = new Date()
    const prvious = today.setDate(today.getDate() + 7); 
    const [Dates, setDates] = useState({
        from : new Date(),
        to : new Date(prvious)
    })
    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [err, setErr] = React.useState({})
    const [checked, setChecked] = React.useState(false);
    const [massg,setMassg] = React.useState({})
    const totalSteps = () => {
        return steps.length;
      };
    
      const completedSteps = () => {
        return Object.keys(completed).length;
      };
    
      const isLastStep = () => {
        return activeStep === totalSteps() - 1;
      };
    
      const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
      };
    
      const handleNext = () => {
        
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? 
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;

        if((newActiveStep === 2) && !validate()){
            setActiveStep(1)
        }else {
            setActiveStep(newActiveStep);
        }
        
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
    
      const handleStep = (step) => () => {
        setActiveStep(step);
      };
    
      const handleComplete = () => {
    
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
      };
    
      const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
      };

      React.useEffect(()=>{
        const reg = localStorage.getItem('email')
        if(!reg){
            setModal({
                display : true,
                content : <SignUp />
            })
        }else if(props.location.state === undefined){
            history.push('/Physiologist')
        }else {
            setSelected(props.location.state)
            SlotInfoCollect()
        }

        
        
      },[])

      const SlotInfoCollect =  ()=>{
         
          const sendDate = selectedDate.toLocaleDateString("en-CA")
          setLoading(true)
         return fetch(Api+'slotInfo&date='+sendDate+'&phy_id='+props.location.state.id)
          .then(Response=>Response.json())
          .then(data=>{
              if(typeof(data) === 'object'){
                  setLoading(false)
                  setSlotInfo({totalSlot: data.slots, usedSlot : data.appointments})
                  return data
                  
              }else{
                  setMassg({
                      open : true,
                      massg : 'Something went wrong',
                      severity : 'error'
                  })
              }
          })
          .catch(err=>{
            setMassg({
                open : true,
                massg : 'Faild to connect to server',
                severity : 'error'
            })
          })

          
      }
      const onChangeDate = (val)=> {
        setSelectedDate(val)
        SlotInfoCollect()
        
      }

      const userProblems = (e)=>{
        setUserProblem({
            ...userProble,
            [e.target.name] : e.target.value
        })
      }

      const validate = ()=>{
          let valid = true
          let error = {}
          if(!userProble.subject){
              valid  = false
              error['subject'] = true
          }else if(!userProble.problem) { 
              valid  = false
              error['problem'] = true
          }

          setErr(error)
          return valid
      }

      const handleSuccessPayment = (res,db_id)=>{
          setLoading(true)
          SlotInfoCollect().then(slot=>{
            const data = new FormData
            data.append('order_id',res.razorpay_order_id)
            data.append('signature',res.razorpay_signature)
            data.append('pay_id',res.razorpay_payment_id)
            data.append('id',db_id)
            data.append('token_no',Number(slot.appointments)+1)
            fetch(Api+'appointConfirm',{
                method : 'POST',
                body : data
            })
            .then(res=>res.json())
            .then(data=>{
                setLoading(false)
                history.push('/MyAppointments')
            })
            .catch(err=>{
                setMassg({
                    open : true,
                    massg : 'Faild to connect to server',
                    severity : 'error'
                })
            })
          })
          
          

      }

      const handleFaildPyment = (db_id,pay_id)=>{
        fetch(Api+'appointPymentFaild',{
            method : 'POST',
            body : JSON.stringify({
                id : db_id,
                status : 'failed',
                pay_id : pay_id
            })
        })
        .then(res=>res.json())
        .then(data=>{
            setMassg({
                open : true,
                massg : 'Payment faild, Please try again',
                severity : 'error'
            })
        })
        .catch(err=>{
            setMassg({
                open : true,
                massg : 'Faild to connect to server',
                severity : 'error'
            })
        })
      }

      const makePayment = (id, db_id)=>{
        const options = {
            key: "rzp_live_WtcYilBfvCvIj5", // Enter the Key ID generated from the Dashboard
            amount: Number(selected.price) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Her Oasis",
            description: "Book your appointment",
            image: logo,
            order_id : id,
            handler: function (response) {
              handleSuccessPayment(response,db_id)
            },
            prefill: {
              email: localStorage.getItem('email'),
            },
            theme: {
              color: "#3399cc",
            },
        };
        
        const rzp1 = new Razorpay(options);
        
        rzp1.on("payment.failed", function (response) {
            handleFaildPyment(db_id,response.error.metadata.payment_id)
        });
        
        rzp1.open();
      }

      const saveData = ()=>{
            SlotInfoCollect().then(data=>{
                
                if((Number(data.slots) - Number(data.appointments))<1){
                    setActiveStep(0)
                    setMassg({
                        open : true,
                        massg : 'Sorry,No slots available',
                        severity : 'error'
                    })
                }else if(!checked){
                    setMassg({
                        open : true,
                        massg : 'Please agree to our informed consent',
                        severity : 'error'
                    })
                }
                else {
                    const formData = new FormData
                    formData.append('appoint_for',selectedDate.toLocaleDateString("en-CA"))
                    formData.append('phy_id',selected.id)
                    formData.append('userSubject', userProble.subject)
                    formData.append('userProblem', userProble.problem)
                    formData.append('price',selected.price)
                    formData.append('email',localStorage.getItem('email'))
                    setLoading(true)
                    fetch(Api+'updateAppointmanet',{
                        method : 'POST',
                        body : formData
                    })
                    .then(Response=>Response.json())
                    .then(data=>{
                        console.log(data)
                        setLoading(false)
                        if(data.status === 201){
                            makePayment(data.order_id, data.db_id)
                        }else {
                            setMassg({
                                open : true,
                                massg : 'Something went wrong',
                                severity : 'error'
                            })
                        }
                    })
                    .catch(err=>{
                        setMassg({
                            open : true,
                            massg : 'Faild to connect to server',
                            severity : 'error'
                        })
                    })
                }
            })
             
          

      }

      const handleChangeAgree = (event) => {
        setChecked(event.target.checked);
      };


      const Modal = ()=>{

        return (
            <Dialog
                open={modal.display}
                maxWidth="xl"
                scroll="body"
            >
            <DialogContent
                style={{
                    padding : 0,
                    margin : 0
                }}
            >
            {modal.content}
            </DialogContent>
            </Dialog>
        )
      }


      const SetSlot = ()=> {
          return (
            <div>
            <Box className={classes.form}>
            <ListItem>
                <ListItemIcon>
                    <Avatar
                        src={ImageApi+selected.profile}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={selected.name}
                    secondary={selected.department}
                />
                <Button style={{color: COLORS.orange}} onClick={()=>history.push('/Physiologist')}>
                    <IconContext.Provider value={{ color: COLORS.orange, size: 16,style : {marginTop: 5,marginLeft: 10} }}>
                        <div>
                            <RiExchangeBoxFill />
                        </div>
                    </IconContext.Provider>
                    Change
                </Button>
            </ListItem>
            <Calendar
                onChange={(val) => onChangeDate(val)}
                value={selectedDate}
                className={classes.calender}
                minDate={Dates.from}
                maxDate={Dates.to}
            />
            {
                slotInfo.totalSlot - slotInfo.usedSlot  === 0 ? (
                    <Alert className={classes.alert} severity="error">{slotInfo.totalSlot - slotInfo.usedSlot} slots available for selected date</Alert>
                ) : slotInfo.totalSlot - slotInfo.usedSlot < 5 ? (
                    <Alert className={classes.alert} severity="warning">{slotInfo.totalSlot - slotInfo.usedSlot} slots available for selected date</Alert>
                ) : (
                    <Alert className={classes.alert} severity="info">{slotInfo.totalSlot - slotInfo.usedSlot} slots available for selected date</Alert>
                )
            }
            
            <Button 
                    className={classes.btn} 
                    variant="contained" 
                    size="large"
                    fullWidth
                    onClick={handleComplete}
                    disabled={slotInfo.totalSlot - slotInfo.usedSlot <= 0 ? true : false}
                    style={{
                        backgroundColor : COLORS.primary,
                        color : COLORS.white
                    }}
                >    
                    Next
                    <IconContext.Provider value={{ color: COLORS.white, style:{marginLeft :10} }}>
                    <div>
                        <FaArrowRight />
                    </div>
                    </IconContext.Provider>
                    
                </Button> 
            </Box>
            </div>
          )
      }
      const Payment = ()=> {

        return (
            <Box>
                
                <ListItem>
                    <ListItemIcon>
                    <IconContext.Provider value={{ color: COLORS.lightBlack, size: 50 }}>
                        <div>
                            <RiSecurePaymentFill />
                        </div>
                    </IconContext.Provider>
                    </ListItemIcon>
                    <ListItemText 
                        primary={<Typography className={classes.payText}>Payment Information</Typography>}
                        secondary={
                            (<Box className={classes.priceInfo}>
                            <IconContext.Provider value={{ color: COLORS.extraLightBlack, size: 35 }}>
                                <div>
                                    <BiRupee />
                                </div>
                            </IconContext.Provider>
                            <div className={classes.payTextSec}>{selected.price}</div>
                            </Box>)
                        }
                    />
                </ListItem>
                {
                   paySure.map((item,index)=>
                   <ListItem key={index}>
                    <ListItemIcon>
                        <IconContext.Provider value={{ color: COLORS.extraLightBlack, style:{marginLeft :10} }}>
                        <div>
                            {item.icon}
                        </div>
                        </IconContext.Provider>
                    </ListItemIcon>
                    <ListItemText 
                        primary={<Typography  className={classes.paySureText}>{item.head}</Typography>}
                       
                        
                    />
                </ListItem>
                   )
               }

               <div style={{display : 'flex'}}>
                    <Checkbox
                        checked={checked}
                        onChange={handleChangeAgree}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    
                    <InformedConsent dep={!props.location.state ? null : props.location.state.department} />

               </div>
                <Button 
                    className={classes.btn} 
                    variant="contained"  
                    size="large"
                    fullWidth
                    onClick={saveData}
                    style={{
                        backgroundColor : COLORS.primary,
                        color : COLORS.white
                    }}
                    disabled={loading}
                >

                {
                loading ? (
                    <Loader type="bubble-top" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={20} />
                ) : (
                    <div style={{display : 'flex'}}>
                        Make Payment
                        <IconContext.Provider value={{ color: COLORS.white, style:{marginLeft :10} }}>
                        <div>
                            <FaArrowRight />
                        </div>
                        </IconContext.Provider>
                    </div>
                )
                }
                    
                    
                </Button>
               

            </Box>
        )
      }
    return(
        <Container  style={{  padding : isMobile ?theme.spacing(2):  theme.spacing(20),paddingTop:  theme.spacing(5),}}>
            <Modal />
            <Box className={classes.container}>
            <div className={classes.heading}>Book an Appointment</div>
            <Divider />
            <Grid container  spacing={2}>
                
                <Grid item xs={12} style={{display: 'flex',alignItems: 'center'}} sm={6}>
                    <img src={calender} className={classes.img} />
                </Grid>
                <Grid item xs={12} sm={5} className={classes.rightCon} >
                    
                    


                    <Box sx={{ width: '100%' }}>
                    <Stepper style={{marginTop: 20,marginBottom: 20}} nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" >
                            {label}
                            </StepButton>
                        </Step>
                        ))}
                    </Stepper>
                    <div>
                        {allStepsCompleted() ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                        ) : (
                        <React.Fragment>
                            {
                                activeStep === 0 ? (
                                    <SetSlot />
                                ) : activeStep === 1 ? (
                                    <Box className={classes.form}>
                                        <TextField
                                            label="Subject"
                                            variant="outlined"
                                            name="subject"
                                            value={userProble.subject}
                                            onChange={userProblems}
                                            fullWidth
                                            error={err.subject}
                                            
                                        />
                                        <div style={{marginTop: 10}}>
                                        </div>
                                        <TextField
                                            label="Describe Your Problem"
                                            variant="outlined"
                                            className={classes.TextField}
                                            fullWidth
                                            name="problem"
                                            value={userProble.problem}
                                            onChange={userProblems}
                                            multiline
                                            error={err.problem}
                                            
                                        />
                                        <div style={{marginTop: 10}}>
                                        </div>

                                        <Button 
                                            className={classes.btn} 
                                            variant="contained"  
                                            size="large"
                                            fullWidth
                                            onClick={handleComplete}
                                            style={{
                                                backgroundColor : COLORS.primary,
                                                color : COLORS.white
                                            }}
                                        >    
                                            Next
                                            <IconContext.Provider value={{ color: COLORS.white, style:{marginLeft :10} }}>
                                            <div>
                                                <FaArrowRight />
                                            </div>
                                            </IconContext.Provider>
                                            
                                        </Button> 
                                        
                                        
                                        
                                    </Box>
                                ) : (
                                    <Payment />
                                )
                            }
                            {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleNext} sx={{ mr: 1 }}>
                                Next
                            </Button>
                            {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                    Step {activeStep + 1} already completed
                                </Typography>
                                ) : (
                                <Button onClick={handleComplete}>
                                    {completedSteps() === totalSteps() - 1
                                    ? 'Finish'
                                    : 'Complete Step'}
                                </Button>
                                ))}
                            </Box> */}
                        </React.Fragment>
                        )}
                    </div>
                    </Box>
                    
                </Grid>
            </Grid>
            </Box>

            <SnakBarNew setMassg={setMassg} massg={massg} />
        </Container>
    )
}


const useStyle = makeStyles(theme=>({
    img : {
        width: '80%',
        marginLeft: '10%'
    },
    heading : {
        fontFamily : 'Raleway',
        padding: 10,
        fontSize : 26,
        textAlign : 'center'
    },
    subHead : {
        paddingLeft : 25,
        color : COLORS.lightBlack,
        fontFamily : 'Raleway',
    },
    TextField : {
        marginTop: 5,
        marginBottom : 5,
        marginLeft: 5
    },
    form : {
        padding : 10,
        paddingTop: 0
        
    },
    btn : {
        backgroundColor: COLORS.primary,
        color: COLORS.white,
        marginLeft: 5,
        marginTop: 10,
        '&:hover' : {
            backgroundColor: COLORS.primary,
            
        }
    },
    btnIcon:{
        color: COLORS.white,
        fill : 'white'
        
    },
    container : {
        border: '1px solid',
        borderColor: COLORS.gery,
        borderRadius: 10,
        backgroundColor: COLORS.white
    },
    rightCon : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
       
    },
    calender : {
        borderRadius: 15,
        borderColor: COLORS.gery

    },
    alert : {
        marginTop : 10,
        marginBottom : 5
    },
    paymentInfo : {
        display: 'flex',
        alignItems:'center'
    },
    payText: {
        fontFamily : 'Raleway',
        marginTop: 10
    },
    payTextSec: {
        marginTop: -8,
        fontSize: 30
    },
    priceInfo : {
        display: 'flex',
        alignItems:'center',
       
        
    },
    paySureText : {
        fontSize: 12,
        color: COLORS.extraLightBlack,
        fontFamily : 'Raleway',
        margin:0
    }
}))
import React from "react";
import {
    Container,
    Grid,
    ListItemText,
    Paper,
    Box,
    Typography,
    Button,
    useMediaQuery,
    useTheme
} from '@mui/material'
import COLORS from "../consts/colors";
import {makeStyles} from '@mui/styles'
import {MdDateRange} from 'react-icons/md'
import {BiTimeFive} from 'react-icons/bi'
import Profile from "./account/profile";

export default function Account(){

    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return(
        <Container >
            <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12} sm={6}>
                    <Box
                        sx={{
                            m : 1,
                            p : {
                                xs : 1
                            },
                            backgroundColor : COLORS.white,
                            borderRadius: 2
                        }}
                    >
                    <div className={classes.heading}>My Appointments</div>
                    <Paper className={classes.paper}>
                        <Box className={classes.leftBox}>
                           <Box style={{width: '70%'}}>
                            <   h4 style={{margin: 0,marginBottom:15}}>Dr. Naznin Sultana</h4>
                                <div style={{display:'flex',alignItems:'center',color: COLORS.lightBlack}}>
                                    <MdDateRange style={{marginRight: 10}} size={20} />
                                    <Typography>28 sep, 21</Typography>
                                </div>
                                <div style={{display:'flex',alignItems:'center',color: COLORS.lightBlack}}>
                                    <BiTimeFive style={{marginRight: 10}} size={20} />
                                    <Typography>Not updated yet</Typography>
                                </div>
                                <Box style={{display :'flex',marginTop: 10}}>
                                    <Button>
                                        More
                                    </Button>
                                    <Button>
                                        Report
                                    </Button>
                                </Box>
                            </Box>
                            <Typography color={COLORS.orange}>Pending</Typography>

                        </Box>
                        <Box className={classes.rightBox}>
                            <Typography className={classes.tokenNo}>05</Typography>
                            <Typography className={classes.token}>is your token Number</Typography>
                        </Box>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Box className={classes.leftBox}>
                           <Box style={{width: '70%'}}>
                            <h4 style={{margin: 0,marginBottom:15}}>Dr. Naznin Sultana</h4>
                                <div style={{display:'flex',alignItems:'center',color: COLORS.lightBlack}}>
                                    <MdDateRange style={{marginRight: 10}} size={20} />
                                    <Typography>28 sep, 21</Typography>
                                </div>
                                <div style={{display:'flex',alignItems:'center',color: COLORS.lightBlack}}>
                                    <BiTimeFive style={{marginRight: 10}} size={20} />
                                    <Typography>Not updated yet</Typography>
                                </div>
                                <Box style={{display :'flex',marginTop: 10}}>
                                    <Button>
                                        More
                                    </Button>
                                    <Button>
                                        Report
                                    </Button>
                                </Box>
                            </Box>
                            <Typography color={COLORS.orange}>Pending</Typography>

                        </Box>
                        <Box className={classes.rightBox}>
                            <Typography className={classes.tokenNo}>04</Typography>
                            <Typography className={classes.token}>is your token Number</Typography>
                        </Box>
                    </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box
                        sx={{
                            m : 1,
                            p : {
                                xs : 1
                            },
                            backgroundColor : COLORS.white,
                            borderRadius: 2
                        }}
                    >
                        <Profile />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

const useStyle = makeStyles({
    heading : {
        fontFamily : 'Raleway',
        paddingLeft : 20,
        fontSize : 26
    },
    leftBox : {
        width : '70%',
        padding: 10,
        display : "flex"
    },
    rightBox : {
        width : '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.primary,
        borderTopRightRadius : 10,
        borderBottomRightRadius: 10,
        padding: 10

    },
    paper : {
        display:'flex',
        borderRadius: 10,
        marginTop :15
        
    },
    tokenNo : {
        fontSize : 60,
        color: COLORS.white
    },
    token : {
        fontSize : 14,
        color: COLORS.white
    }
})


import {
    Container,
    Paper,
    Grid,
    Typography,
    FormControl,
    TextField,
    Box,
    Button,
    useTheme,
    useMediaQuery
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import { Link } from 'react-router-dom'
import auth from '../../assets/forgot.png'
import COLORS from '../../consts/colors'
import '../../fonts.css'
import {FaArrowRight} from 'react-icons/fa'
import {IconContext} from "react-icons";
import React from 'react'
import Loader from "react-js-loader";
import isEmail from 'validator/lib/isEmail';
import {Api} from '../../process/api'
import { useHistory } from 'react-router'
import SnakBarNew from '../../consts/notification'
export default function ForgotPass(){
    const history = useHistory()
    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [data, setData] = React.useState({})
    const [error, setError] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [otp,setOtp] = React.useState(Math.floor(1000 + Math.random() * 9000))
    const [sendStatus, setSendStatus] = React.useState(false)
    const [Massg, setMassg] = React.useState({})
    const onChange = (e)=>{
        setData({
            ...data,
            [e.target.name] : e.target.value
        })
    }

    const validate = ()=>{
        let error = {}
        let valid = true

        if(!data.email){
            valid = false
            error['email'] = true
            
        }else {
            if(isEmail(data.email)){
                error['not_email'] = false
            }else{
                valid = false
                error['not_email'] = true
            }
        }
        
        

        setError(error)

        return valid
        
    }

    const onSubmit = ()=>{
        if(validate()){
       
        setLoading(true)
        fetch(Api+'ForgotPass',{
            method : 'POST',
            body : JSON.stringify({
                email : data.email,
                otp : otp
            })
        })
        .then(Response=>Response.json())
        .then(data=>{
            console.log(data)
            setLoading(false)
            if(data.status === 201){
                setSendStatus(true)
            }else{
                setMassg({
                    massg : data.massg,
                    severity : 'error',
                    open : true
                })
            }
        })
        .catch(err=>{
            console.log(err)
            setMassg({
                massg : "Couldn't connect to the server" ,
                severity : 'error',
                open : true
            })
            setLoading(false)
        })
             
    }
    }

    const newValidate = ()=>{
        let error = {}
        let valid = true
        if(!data.new_pass){
            valid = false
            error['new_pass'] = true
            
        }if(!data.conf_password) {
            valid = false
            error['conf_password'] = true
        }if(data.new_pass != data.conf_password ){
            valid = false
            error['conf_password'] = true
        }if(!data.t_otp) {
            valid = false
            error['t_otp'] = true
        }if(data.t_otp != otp){
            console.log(otp)
            console.log(data.t_otp)
            valid = false
            error['t_otp'] = true
        }

        setError(error)
        return valid
    }

    const OnChangePassword = ()=>{
        if(newValidate()){
       
            setLoading(true)
            fetch(Api+'ChangePass',{
                method : 'POST',
                body : JSON.stringify(data)
            })
            .then(Response=>Response.json())
            .then(data=>{
                console.log(data)
                setLoading(false)
                if(data.status === 201){
                    history.push('/Home')
                }else{
                    setMassg({
                        massg : data.massg,
                        severity : 'error',
                        open : true
                    })
                }
            })
            .catch(err=>{
                setMassg({
                    massg : "Couldn't connect to the server" ,
                    severity : 'error',
                    open : true
                })
                setLoading(false)
            })
                 
        }
    }
    return(
        <Container maxWidth="md" style={{ paddingTop:  theme.spacing(10),}}>
            <Grid container component={Paper} elevation={1}  style={{
                backgroundColor : '#f7f7f7',
                borderRadius: 20,
            }}>
                <Grid item xs={12} sm={6} >
                    <img src={auth} className={classes.img} />
                </Grid>
                <Grid item xs={12} sm={6} style={{
                     borderRadius : 20,
                     padding : theme.spacing(isMobile ? 1 : 5),
                     backgroundColor : COLORS.white
                }} >
                <div className={classes.rightCon}>
                    <div className={classes.heading}>Change Password</div>
                    <div className={classes.subHead}>
                      Sign in to your account <Link to="/SignIn" style={{textDecoration : 'none'}}>Sign In</Link>
                    </div>
                    
                    {
                        sendStatus ? (
                            <Box className={classes.form}>
                                <div className={classes.TextField}>
                                    <TextField
                                        label="Enter OTP"
                                        variant="outlined"
                                        className={classes.TextField}
                                        fullWidth
                                        name="t_otp"
                                        type="number"
                                        value={data.t_otp}
                                        onChange={onChange}
                                        error={error.t_otp}
                                       
                                    />
                                </div>
                                <div className={classes.TextField}>
                                    <TextField
                                        label="Enter New Password"
                                        variant="outlined"
                                        className={classes.TextField}
                                        fullWidth
                                        name="new_pass"
                                        type="password"
                                        value={data.new_pass}
                                        onChange={onChange}
                                        error={error.new_pass}
                                        
                                    />
                                </div>
                                <div className={classes.TextField}>
                                    <TextField
                                        label="Confirm New Password"
                                        variant="outlined"
                                        className={classes.TextField}
                                        fullWidth
                                        name="conf_password"
                                        type="password"
                                        value={data.conf_password}
                                        onChange={onChange}
                                        error={error.conf_password}
                                        helperText={error.not_email ? <Typography style={{fontSize :12}} color="red">Please enter a valid email</Typography> : ''}
                                    />
                                </div>
                                <div className={classes.TextField}>
                                    <Button 
                                        variant="contained" 
                                        size="large"
                                        fullWidth
                                        onClick={OnChangePassword}
                                        style={{
                                            backgroundColor: COLORS.primary,
                                            color: COLORS.white,
                                            marginTop: 0,
                                            '&:hover' : {
                                                backgroundColor: COLORS.primary,
                                                
                                            }
                                        }}
                                    >    
                                    {
                                        loading ? (
                                            
                                            <Loader type="bubble-top" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={20} />
                                        
                                        ) : (
                                            <IconContext.Provider value={{ color: COLORS.white, style:{marginLeft :10} }}>
                                            <div>
                                                Change password
                                                <FaArrowRight />
                                            </div>
                                            </IconContext.Provider>
                                        )
                                    }
                                        
                                    </Button> 
                                </div>
                            </Box>
                        ) : (
                            <Box className={classes.form}>
                            <div className={classes.TextField}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    className={classes.TextField}
                                    fullWidth
                                    name="email"
                                    type="email"
                                    value={data.email}
                                    onChange={onChange}
                                    error={error.email}
                                    helperText={error.not_email ? <Typography style={{fontSize :12}} color="red">Please enter a valid email</Typography> : ''}
                                />
                            </div>
                            
                            <div className={classes.TextField}>
                                <Button 
                                    variant="contained" 
                                    size="large"
                                    fullWidth
                                    onClick={onSubmit}
                                    style={{
                                        backgroundColor: COLORS.primary,
                                        color: COLORS.white,
                                        marginTop: 0,
                                        '&:hover' : {
                                            backgroundColor: COLORS.primary,
                                            
                                        }
                                    }}
                                >    
                                {
                                    loading ? (
                                        
                                        <Loader type="bubble-top" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={20} />
                                    
                                    ) : (
                                        <IconContext.Provider value={{ color: COLORS.white, style:{marginLeft :10} }}>
                                        <div>
                                             Send Otp
                                            <FaArrowRight />
                                        </div>
                                        </IconContext.Provider>
                                    )
                                }
                                    
                                </Button> 
                            </div>
                            </Box>  
                        )
                    }
                    
                        
                        
                    
                </div>
                </Grid>
            </Grid>
            <SnakBarNew setMassg={setMassg} massg={Massg} />
        </Container>
    )
}


const useStyle = makeStyles(theme=>({
    img : {
        width: '60%',
        padding: '20%',
    },
    heading : {
        fontFamily : 'Raleway',
        paddingLeft : 20,
        fontSize : 26
    },
    subHead : {
        paddingLeft : 20,
        color : COLORS.lightBlack,
        fontFamily : 'Raleway',
    },
    TextField : {
        marginTop:  10,
        marginBottom : 10,
        marginLeft: 5
    },
    form : {
        padding : 15,
        
    },
    btnIcon:{
        color: COLORS.white,
        fill : 'white'
        
    },
    container : {
        border: '1px solid',
        borderColor: COLORS.gery,
        borderRadius: 10,
        backgroundColor : COLORS.white
    },
    rightCon : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
        height : '100%',
        borderRadius : 15,
        backgroundColor : COLORS.white
       
       
    }
}))
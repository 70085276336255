import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Container } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Api } from '../../process/api';
import COLORS from '../../consts/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function InformedConsent(props){
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState('')
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    React.useEffect(()=>{
        console.log(Api+'consetByDep&department='+props.dep)
        fetch(Api+'consetByDep&department='+props.dep)
        .then(res=>res.json())
        .then(data=>setData(data))
        .catch(err=>console.log(err))
    },[])


    return(
        <Container>
            <Toolbar>
            <Typography>I agree to</Typography>
            <Button style={{textTransform : 'lowercase'}} onClick={handleClickOpen}>
                <Typography> Informed consent</Typography>
            </Button>
            </Toolbar>
            
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative',backgroundColor : COLORS.primary }}>
                <Toolbar>
                    <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    >
                    <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {props.dep}
                    </Typography>
                    
                </Toolbar>
                </AppBar>
                <div dangerouslySetInnerHTML={{__html: data}}>

                </div>
            </Dialog>
        </Container>
    )
}
import React from "react";
import {  Toolbar,Divider, Typography,useMediaQuery,useTheme, Box, Collapse, Button,IconButton, ListItemIcon, ListItemText,ListItem } from "@mui/material";
import {makeStyles} from '@mui/styles'
import AppBar from '@mui/material/AppBar';
import logo from '../assets/logo.png'
import Home from "./home";
import { Link,BrowserRouter,useLocation, useHistory } from "react-router-dom";
import COLORS from "../consts/colors";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import {FcHome,FcAbout,FcCustomerSupport,FcOrganization,FcBusinessman,FcImport,FcExport,FcRules} from 'react-icons/fc'
import {IconContext} from "react-icons";
import { Api } from "../process/api";
import '../fonts.css'
import {BiLogOut,BiLogIn} from 'react-icons/bi'
import {BsFillPersonFill} from 'react-icons/bs'
import {FaWpforms} from 'react-icons/fa'
import '../consts/styles/animation.css'
import Zoom from 'react-reveal/Zoom'
export default function Appbar({refs,setRefs}){
    
    const history = useHistory()
    const [open, setOpen] = React.useState(false)
    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { pathname } = useLocation();
    const [navItems, setNavItems] = React.useState([])
    const [collected, setCollected] = React.useState([])
    React.useEffect(() => {
        setNavItems(collected.concat([
            
            {
                name : 'Ambassadors',
                // link : '/Physiologist',
                // icon : <FcOrganization />
                
            },
            {
                name : 'Blogs',
                // link : '/ContactUs',
                // icon: <FcCustomerSupport />
                
            },
            {
                name : 'Contact Us',
                // link : '/ContactUs',
                // icon: <FcCustomerSupport />
                
            },
            
            {
                name : 'Gallery',
                // link : '/ContactUs',
                // icon: <FcCustomerSupport />
                
            },
            
            
            
            
            
        ]))

        

       
    }, [collected]);

    

    React.useEffect(()=>{
        fetch(Api+'headofnav')
        .then(response=>response.json())
        .then(data=>{
            if(Array.isArray(data)){
                setCollected(data)
            }
        })
        .catch(err=>console.log(err))
    },[pathname])

    const setColor = (val)=>{
        let color = '#fff';
        if(val === pathname){
            color = COLORS.secondary
        }

        return color;
    }
    const [y, setY] = React.useState(window.scrollY);
    const [display, setDisplay] = React.useState({
                display : 'flex',
                margin : 60
    })
    const handleNavigation = React.useCallback(
    e => {
        const window = e.currentTarget;
        if (y < 100) {
         setDisplay({
             display : 'flex',
             margin : 60
         })
        } else if (y < window.scrollY) {
            setDisplay({
                display : 'none',
                margin : 0
            })
        }
        setY(window.scrollY);
    }, [y]
    );

    React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
        window.removeEventListener("scroll", handleNavigation);
    };
    }, [handleNavigation]);


    const handlePress = (item)=>{
        // props.onPress(item)
        setRefs(item)
        setOpen(false)
        history.push('/')
       
    }

    const handleLogout = ()=>{
        setOpen(false)
        localStorage.removeItem('email')
        localStorage.removeItem('regitered')
        localStorage.removeItem('emailVarified')

        window.location.replace('/')
    }
    return(
        <div style={{marginBottom: isMobile ? 0 : display.margin}}>
        {
            isMobile ? '' : (
                <AppBar color="inherit" style={{display : display.display,height:60}} elevation={0}>
                    <Toolbar style={{width: '95%',display : 'flex',justifyContent : 'space-between'}}>
                        <Link to="/" style={{textDecoration : 'none', color: '#000'}}>
                        <div style={{display :'flex',alignItems :'center'}}>
                            {/* <img src={logo} width={70} /> */}
                            <div className={classes.mainHead}>
                                <div style={{color: COLORS.primary}}>H</div>er  <div style={{color: COLORS.primary}}> O</div>asis
                            </div>
                        </div>
                        </Link>
                        {
                            localStorage.getItem('regitered') ? (
                                <div>
                                    <Link to="/Home" style={{textDecoration : 'none', color: '#000'}}>
                                        <Button variant="contained" style={{backgroundColor : COLORS.primary}} startIcon={<BsFillPersonFill />} >
                                            My account
                                        </Button>
                                    </Link>
                                    <Button onClick={()=>handleLogout()} variant="contained" style={{marginLeft : 5}} color="error" startIcon={<BiLogOut />}>
                                        
                                        Sign Out
                                    </Button>
                                    <Link to="/Register" style={{textDecoration : 'none', color: '#000'}}>
                                        <Button  variant="contained" style={{backgroundColor : COLORS.primary,marginLeft : 5}} startIcon={<FaWpforms />}>
                                        Register
                                        </Button>
                                    </Link>
                                </div>
                            ) : (
                                <div>
                                    <Link to="/SignIn" style={{textDecoration : 'none', color: '#000'}}>
                                    <Button  variant="contained" style={{backgroundColor : COLORS.primary}} startIcon={<BiLogIn />}>
                                        Sign In
                                    </Button>
                                    </Link>
                                    <Link to="/SignUp" style={{textDecoration : 'none', color: '#000'}}>
                                    <Button  variant="contained" style={{backgroundColor : COLORS.primary,marginLeft : 5}} startIcon={<FaWpforms />}>
                                        Sign Up
                                    </Button>
                                    </Link>
                                    <Link to="/Register" style={{textDecoration : 'none', color: '#000'}}>
                                        <Button  variant="contained" style={{backgroundColor : COLORS.primary,marginLeft : 5}} startIcon={<FaWpforms />}>
                                        Register
                                        </Button>
                                    </Link>
                                </div>
                            )
                        }
                                
                                    
                    </Toolbar>
                    
                </AppBar>
            )
        }
        
        <div>
        <AppBar  color="inherit" style={{top : isMobile ? 0 : display.margin,borderTop: '2px solid' +COLORS.primary,}}  position="fixed" elevation={0}>
            <div className={classes.appbar_next} style={{height : isMobile ? 70 : 50}}>
            {/* <Toolbar className={classes.leftContent}>
                <img src={logo} width={70} />
            </Toolbar> */}
            
                {   isMobile ? 
                        <div style={{display : 'flex',width : '100%'}}>
                        <Toolbar className={classes.leftContent}>
                        <div style={{display :'flex',alignItems :'center'}}>
                            {/* <img src={logo} width={70} /> */}
                            <div className={classes.mainHead}>
                                <div style={{color: COLORS.primary}}>H</div>er  <div style={{color: COLORS.primary}}> O</div>asis
                            </div>
                        </div>
                        </Toolbar>
                        <Toolbar style={{marginLeft : 'auto'}}>
                            <IconButton>
                                <MenuOpenIcon onClick={()=>setOpen(!open)} />
                            </IconButton>
                        </Toolbar>
                        </div>
                        
                    : <Toolbar  className={classes.rightContent}>
                        {
                            navItems.map((item,index)=>
                                <Button   onClick={()=>handlePress(item.name)}  key={index}>
                                    <div  >
                                    <Typography style={{color : COLORS.black,fontSize : 12,}}>{item.name}</Typography>
                                    </div>
                                </Button>
                            )
                        }
                        
                        
                      </Toolbar>
                    
                }

           </div>
        </AppBar>
        
        {
            isMobile ? 
               
                        <Collapse orientation="horizontal" in={open}>
                         
                            <Box className={classes.mobileMenu} >
                           
                            {
                                navItems.map((item,index)=>
                                <div  style={{display : 'flex',flexDirection : 'column',justifyContent : 'flex-start'}}>
                                    <Button size="large" onClick={()=>handlePress(item.name)} key={index}>
                                        <Typography className={classes.navItemText} style={{color : '#000'}}>{item.name}</Typography>
                                    </Button>
                                </div>
                                )
                            }
                            {
                            localStorage.getItem('regitered') ? (
                                <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                    <Link to="/Home" onClick={()=>setOpen(false)} style={{textDecoration : 'none', color: '#000'}}>
                                        <Button size="large">
                                            <Typography className={classes.navItemText} style={{color : '#000'}}>My Account</Typography>
                                        </Button>
                                    </Link>
                                    
                                        <Button onClick={()=>handleLogout()} size="large">
                                            <Typography className={classes.navItemText} style={{color : '#000'}}>Sign Out</Typography>
                                        </Button>
                                        <Link to="/Register" onClick={()=>setOpen(false)} style={{textDecoration : 'none', color: '#000'}}>
                                        <Button size="large">
                                            <Typography className={classes.navItemText} style={{color : '#000'}}>Register</Typography>
                                        </Button>
                                    </Link>
                               
                                </div>
                            ) : (
                                <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                    <Link to="/SignIn" onClick={()=>setOpen(false)} style={{textDecoration : 'none', color: '#000'}}>
                                        <Button size="large">
                                            <Typography className={classes.navItemText} style={{color : '#000'}}>Sign In</Typography>
                                        </Button>
                                    </Link>
                                    <Link to="/SignUp" onClick={()=>setOpen(false)} style={{textDecoration : 'none', color: '#000'}}>
                                        <Button size="large">
                                            <Typography className={classes.navItemText} style={{color : '#000'}}>Sign Up</Typography>
                                        </Button>
                                    </Link>
                                    <Link to="/Register" onClick={()=>setOpen(false)} style={{textDecoration : 'none', color: '#000'}}>
                                        <Button size="large">
                                            <Typography className={classes.navItemText} style={{color : '#000'}}>Register</Typography>
                                        </Button>
                                    </Link>
                                </div>
                            )
                            }
                                    
                         
                            </Box>
                            
                        </Collapse>
                    
                        
                    : ''
        }
        </div>
        </div>
    )
}


const useStyle = makeStyles({
    appbar : {
        display: 'flex',
        flexDirection : 'row',
        height: 70,
    },
    appbar_next : {
        display: 'flex',
        flexDirection : 'row',
        alignItems : 'center',
        backgroundColor : 'rgba(128,0,45,0.2)'
    },
    rightContent : {
        justifySelf : 'center',
        width: '90%',
        justifyContent: 'center'
    },
    leftContent : {
        width : '10%'
    },
    link : {
        textDecoration: 'none',
        marginLeft: 15,
        color: "#000",
        textTransform : 'lowercase',
        '&:hover' : {
            backgroundColor : '#000'
        }
    },
    mobileMenu : {
        width: '100%',
        backgroundColor : COLORS.lightGray,
        padding: 20,
        marginTop: 50,
        position: 'fixed',
        top: 0,
        zIndex : 9,
        bottom: 0,
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'center'
    },
    navTextMob: {
        textAlign : 'center',
        fontWeight : 700,
        color: '#fff'
    },
    navItemText : {
        fontSize : 18,
        marginLeft : 10
    },
    navItemTextMob : {
        fontSize : 14,
        marginLeft : 0
    },
    mainHead : {
        fontSize : 26,
        fontWeight: 600,
        marginLeft : 20,
        fontFamily : ['Mate SC', 'serif'],
        display : 'flex'
    }
    
})
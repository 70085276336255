import React from "react";
import { Container,CardMedia,Card,Button, Paper } from "@mui/material";
import {makeStyles} from '@mui/styles'
import Slider from "react-slick";
import COLORS from "../../consts/colors";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Api, ImageApi } from "../../process/api";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from "react-router-dom";
const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    autoplay: true,
      speed: 2000,
      autoplaySpeed: 500,
      cssEase: "linear",
      lazyLoad: true,
  };
function GalleryMIn(props,ref){
    const classes = useStyle()
    const [Data, setData] = React.useState([])

    React.useEffect(()=>{
        fetch(Api+'gallery_min')
        .then(response=>response.json())
        .then(data=>setData(data))
        .catch(err=>console.log(err))
    },[])
   
    return (
        <Container ref={ref != null ? ref : undefined} maxWidth="xl"  component={Paper} className={classes.main}>
        {
            Data.length > 0 ? (
                <div>
                <h2 style={{paddingTop: 20}}>Gallery</h2>
                <hr style={{width : '10%',marginBottom: 30,marginRight :'90%',borderWidth: 3}} />
                <Slider
                {...settings}
                >

                {
                    Data.map((item,index)=> 
                        <div key={index}>
                            <Card style={{margin: 5}}>
                                <CardMedia 
                                    component="img"
                                    height={250}
                                    image={ImageApi+'/gallery/'+item.image_name}
                                    alt="image"
                                />
                            </Card>
                        </div>
                    )
                }
                    
                </Slider>
                <div style={{width: '100%',display: 'flex',justifyContent : 'flex-end'}}>
                <Link to="/Gallery" style={{textDecoration : 'none'}}>
                <Button 
                    variant="contained"
                    style={{
                        backgroundColor : COLORS.orange,
                        margin : 30
                    }}

                    endIcon={<ChevronRightIcon />}

                >
                    Expolore More
                </Button>
                </Link>
                </div>
                </div>
            ) : ''
        }
            
        </Container>
    )
}

const useStyle = makeStyles(theme=>({
    main : {
        backgroundColor : COLORS.lightGray,
        paddingBottom: 40,
        width: '98%',
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'flex-end'
    },
    paper : {
        backgroundColor : COLORS.secondary,
        borderRadius :20,
        margin: 10
    },
    revBox : {
        display : 'flex',
        flexDirection : 'row',
        width: '100%',
        padding : 15
    },
    profileDesign : {
        display : 'flex',
        justifyContent:'center',
        alignItems : 'center',
        width: 60,
        height: 60,
    },
    revHead : {
        paddingLeft: 15,
    },
    review : {
        padding: 20,
        paddingTop : 0
    }
}))

export default React.forwardRef(GalleryMIn)
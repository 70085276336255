

import {Container,Grid,ListItem, Paper,ListItemAvatar, ListItemText,Alert,Box,TextField,FormControl,Button, Typography, Dialog, DialogContent } from '@mui/material'
import {IoMdCall} from 'react-icons/io'
import {IconContext} from "react-icons";
import COLORS from '../consts/colors';
import { makeStyles,useTheme } from '@mui/styles';
import '../fonts.css'
import {IoIosMail} from 'react-icons/io'
import {FaArrowRight} from 'react-icons/fa'
import { Link } from 'react-router-dom'
import React, { forwardRef } from 'react';
import isEmail from 'validator/lib/isEmail';
import { Api } from '../process/api';
import {FcApproval,FcHighPriority} from 'react-icons/fc'
import {MdCall,MdEmail} from 'react-icons/md'
import {FaWhatsapp,FaFacebook} from 'react-icons/fa'
import {AiFillInstagram, AiFillTwitterCircle} from 'react-icons/ai'
import {GoLocation} from 'react-icons/go'
import randomColor from "randomcolor";


const socialHandles = [
    {
        icon : <FaFacebook size={35} style={{margin:10}} color={randomColor()} />,
        link : "https://www.facebook.com/heroasis.in"
    },
    {
        icon : <AiFillInstagram  size={35} style={{margin:10}} color={randomColor()}/>,
        link : "https://www.instagram.com/heroasis.in/"
    },
    {
        icon : <AiFillTwitterCircle size={35} style={{margin:10}} color={randomColor()} />,
        link : "https://twitter.com/HerOasis_in"
    }
]
 function ContactUs(props,ref){
    // const refs = React.createRef()
    const classes = useStyle()
    const [sendData, setSendData] = React.useState({
        email : localStorage.getItem('email')
    })
    const [err, setErr] = React.useState({})
    const [massgData, setmassgData] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [conInfo,setContInfo] = React.useState({})
    React.useEffect(()=>{
        fetch(Api+'contactInfo')
        .then(response=>response.json())
        .then(data=>setContInfo(data))
        
    },[])

    const lists = [
        {
            head : 'Contact No',
            value : conInfo.contact_no,
            icon : <MdCall />
        },
        {
            head : 'Email',
            value : conInfo.email,
            icon : <MdEmail />
        },
        {
            head : 'Location',
            value : conInfo.address,
            icon : <GoLocation size={26} />
    
        }
        
    ]
    const onChange = (e)=>{
        setSendData({
            ...sendData,
            [e.target.name] : e.target.value
        })
    }

    const validate = ()=>{
        let error = {}
        let valid = true
        if(!sendData.f_name){
            valid = false
            error['f_name']  = true
        }
        if(!sendData.l_name){
            valid = false
            error['l_name']  = true
        }
        if(!sendData.email){
            valid = false
            error['email']  = true
        }
        if(!sendData.ph_no){
            valid = false
            error['ph_no']  = true
        }
        if(!sendData.massg){
            valid = false
            error['massg']  = true
        }
        if(!isEmail(sendData.email)){
            valid = false
            error['email']  = true
        }

        setErr(error)
        return valid
    }

    const onSubmit = ()=>{
        if(validate()){
            setLoading(true)
            fetch(Api+'sendMassg',{
                method : 'POST',
                body : JSON.stringify(sendData)
            })
            .then(Response=>Response.json())
            .then(data=>{
                setmassgData(data)
                setLoading(false)
            })
            .catch(err=>console.log(err))
        }
    }
    const handleClose = ()=>{
        setmassgData({})
    }

    const MassgSuccessInfo = ()=>{
        return(
            <Dialog
                open={massgData.status != undefined ? true : false}
                onClose={handleClose}
                
            >
                <DialogContent>
                    {
                        massgData.status === 201 ? (
                            <div style={{display : 'flex',flexDirection: 'column',justifyContent:'center',alignItems:'center'}}>
                                <FcApproval size={80} />
                                <Typography>{massgData.massg}</Typography>
                                <div>
                                    <Link to="/Home" style={{textDecoration : 'none'}}>
                                        <Button
                                            variant="contained"
                                            style={{backgroundColor: COLORS.primary,color: COLORS.white}}

                                        >   
                                            To Home
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{backgroundColor: 'red',color: COLORS.white,marginLeft: 5}}
                                            onClick={handleClose}
                                        >   
                                            close
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <FcHighPriority size={80} />
                            </div>
                        )
                    }
                    
                </DialogContent>
            </Dialog>
        )
    }
    
    return (
        <Container ref={ref != null ? ref : undefined} maxWidth="md" className={classes.main}>
            <MassgSuccessInfo />
            <Grid container component={Paper} spacing={1}>
                <Grid item sm={6} xs={12}style={{
                    backgroundColor : '#963f55',
                    padding: 20,
                    borderRadius : 5,
                }}> 
                    <div>
                    <Typography style={{
                        fontSize : 24,
                        color: COLORS.white,
                        fontFamily : 'Raleway',
                        fontWeight  : 600
                    }}>
                        Contact Information
                    </Typography>
                    <Typography style={{
                        fontSize : 16,
                        color: COLORS.white,
                        fontFamily : 'Raleway'
                    }}>
                        For any query feel free to contact us
                    </Typography>
                    </div>
                    <div style={{marginTop : 40}}>
                    {
                        lists.map((item,index)=>
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection : 'row',
                                color : COLORS.white,
                                marginTop: 10,
                                alignItems : 'center'
                            }}>
                                {item.icon}

                                <div style={{marginLeft: 10}}>{item.value}</div>

                            </div>   
                        )
                    }
                    </div>
                    <div style={{
                        display :'flex',
                        flexDirection : 'row',
                        marginTop : 60
                    }}>

                        {
                                socialHandles.map((item,index)=>
                                <a href={item.link} target="_blank" key={index}  
                                    style={{
                                    textDecoration : 'none',
                                    color : COLORS.white,
                                    marginTop: 5,
                                    display: 'flex',
                                    flexDirection : 'column',
                                    justifyContent : 'center'
                                    
                                    
                                }}>
                                    <div >{item.icon}</div>
                                </a>
                                )
                            }
                    </div>
                    
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Paper elevation={0}>
                    <div>
                    <Typography style={{
                        fontSize : 24,
                        color: COLORS.blackl,
                        fontFamily : 'Raleway',
                        fontWeight  : 600
                    }}>
                        Send a message
                    </Typography>
                    <Typography style={{
                        fontSize : 16,
                        color: COLORS.lightBlack,
                        fontFamily : 'Raleway'
                    }}>
                        For any query feel free to message us
                    </Typography>
                    </div>
                    <Box className={classes.form}>
                        <div className={classes.multiycol}>
                            <div className={classes.TextField}>
                            <TextField
                                label="First Name"
                                variant="outlined"
                                fullWidth
                                name="f_name"
                                value={sendData.f_name}
                                onChange={onChange}
                                error={err.f_name}
                            />
                            </div>
                            <div className={classes.TextField}>
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                name="l_name"
                                value={sendData.l_name}
                                onChange={onChange}
                                error={err.l_name}
                                fullWidth
                            />
                            </div>
                        </div>
                        <div className={classes.TextField}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={sendData.email}
                            onChange={onChange}
                            error={err.email}
                            fullWidth
                        />
                        </div>
                        <div className={classes.TextField}>
                        <TextField
                            label="Contact No"
                            variant="outlined"
                            className={classes.TextField}
                            fullWidth
                            name="ph_no"
                            type="number"
                            value={sendData.ph_no}
                            onChange={onChange}
                            error={err.ph_no}
                        />
                        </div>
                        <div className={classes.TextField}>
                        <TextField
                            label="Message"
                            variant="outlined"
                            className={classes.TextField}
                            fullWidth
                            multiline
                            name="massg"
                            value={sendData.massg}
                            onChange={onChange}
                            error={err.massg}
                        />
                        </div>
                        <Button 
                            className={classes.btn} 
                            variant="outlined" 
                            size="large"
                           fullWidth
                           onClick={onSubmit}
                        >    
                            Send Message 
                            <IconContext.Provider value={{ color: COLORS.white, style:{marginLeft :10} }}>
                            <div>
                                <FaArrowRight />
                            </div>
                            </IconContext.Provider>
                              
                        </Button> 
                        
                        
                        
                    </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

const useStyle =  makeStyles({
    main : {
        marginTop : 70
    },
    avatar : {
        border: '3px solid',
        borderColor : COLORS.primary,
        borderRadius: 100,
        padding: 5
        
    },
    callHead : {
        fontSize : 18,
        marginLeft: 20,
        color: COLORS.lightBlack,
        fontFamily : 'Raleway',
    },
    callSec : {
        fontSize: 20,
        fontWeight: 800,
        color:  COLORS.primary,
        marginLeft: 20,
    },
    callhelp : {
        padding: 10,
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop : 30
    },
    heading : {
        fontFamily : 'Raleway',
        paddingLeft : 20,
        fontSize : 26,
        paddingTop: 20
    },
    subHead : {
        paddingLeft : 20,
        color : COLORS.lightBlack,
        fontFamily : 'Raleway',
    },
    multiycol : {
        display : 'flex',
        flexDirection: 'row',
        
    },
    TextField : {
        marginTop: 5,
        marginBottom : 5,
        marginLeft: 5
    },
    form : {
        padding : 15,
        
    },
    btn : {
        backgroundColor: COLORS.primary,
        color: COLORS.white,
        marginLeft: 5,
        marginTop: 10,
        '&:hover' : {
            backgroundColor: COLORS.primary,
            
        }
    },
    btnIcon:{
        color: COLORS.white,
        fill : 'white'
        
    },
    container : {
        border: '1px solid',
        borderColor: COLORS.gery,
        borderRadius: 10,
    },
    rightCon : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
       
    }
})

export default forwardRef(ContactUs)
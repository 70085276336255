import React from "react";
import { Container, Grid, Button, Paper,useTheme,useMediaQuery,CircularProgress } from "@mui/material";
import { styled,makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import WhyChoose from "./sub_home/whyChosee";
import COLORS from "../consts/colors";
import Reviews from "./sub_home/reviews";
import {Link} from 'react-router-dom'
import Physiologist from "./physiologists";
import play from '../assets/play.png'
import '../fonts.css'
import {Api, ImageApi} from "../process/api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Steps from "./sub_home/steps";
import ContactUs from "./contact";
import AboutHome from "./about/contents";
import GalleryMIn from "./sub_home/gallery.min";
import Zoom from "react-reveal/Zoom"
import logo from '../assets/logo.png'
import Blogs from "./sub_home/blogs";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
      padding: 0,
    },
    '& .MuDialogActions-root': {
      padding: 0,
    },
  }));
  
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 2,
              top: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  export default function Home(props){
    const ref = React.createRef(null)
    const [open, setOpen] = React.useState(false);
    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [wellCome, setWellcome] = React.useState([])
    const [myref, setMyref] = React.useState(props.refs)
    const [loading, setLoading] = React.useState(false)
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(()=>{
        setLoading(true)
        fetch(Api+'ContentCol&type=tagline')
        .then(Response=>Response.json())
        .then(data=>{
            setWellcome(data)
            setLoading(false)
            setVisible(true)
        })
    },[])

    // React.useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //     entries.forEach(entry => setVisible(entry.isIntersecting));
    //     });
    //     observer.observe(domRef.current);
    //     // return () => observer.unobserve(domRef.current);
    // }, []);
    const handleClickOpen = (item) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(()=>{
        setMyref(props.refs)
        if(ref.current != null && loading === false){
            ref.current.scrollIntoView({behavior : 'smooth'})
        }
    },[ref,loading])

    const handleRef = (e)=>{
     
        if(e === props.refs){
            return ref
        }
    }

  

    const Modal = ()=> {

        return (
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="xl"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Select 
                </BootstrapDialogTitle>
                <DialogContent style={{padding : 0,margin: 0}} dividers>
                    <Physiologist />
               
                
                </DialogContent>
               
            </BootstrapDialog>
        )
    }
    const settings = {
        
       
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        lazyLoad: true,
        
    };
    
    return(
        <div>
        {
        loading ? <div style={{display : 'flex',justifyContent: 'center',marginTop: 20}}><CircularProgress /></div> :
        <Container disableGutters maxWidth="xl" className={classes.container}>
            <Modal />
            <div className={classes.homeInfo}>
             <Slider {...settings}>

            {
                wellCome.map((item,index)=>
                    <div key={index}  >
                        {/* {
                            index === 0 ? (
                                <div style={{width: '100%',backgroundImage :`url(${ImageApi+'/contents/'+item.image})`,backgroundRepeat: 'no-repeat',backgroundPosition: 'center',backgroundSize: '100% 100%',height : isMobile ? 750 : 600 }} >
                                    <div style={{height : isMobile ? 750 : 600,padding: 30 }} className={classes.leftContent}>
                                    <Grid container spacing={0} style={{marginTop : isMobile ? 70 : 0}}>
                                        <Grid xs={12} sm={6} style={{justifyContent: 'center',alignItems:'center'}}>
                                            <img src={logo} width={'80%'} style={{marginLeft: '10%'}} />
                                        </Grid>
                                        <Grid xs={12} sm={6} style={{justifyContent: 'center',alignItems:'center'}}>
                                        <Zoom>
                                        <div
                                        style={{display: "flex",justifyContent : 'center'}}
                                        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                                        >
                                            <div style={{color : COLORS.white,width: isMobile ? '95%' : '80%',textAlign:"justify"}} dangerouslySetInnerHTML={{__html: item.content}}>
                                                
                                            </div>
                                            
                                        </div>
                                        <div style={{display : 'flex',justifyContent: 'flex-end',width: '100%',flexDirection : 'row'}} className={`fade-in-content-btn ${isVisible ? 'is-visible' : ''}`}>
                                            <Button onClick={()=>setOpen(true)} size="large" color="primary" variant="contained" style={{marginTop: 20,backgroundColor: COLORS.primary,color: '#fff',width: 200,marginRight : 10}}>
                                                Make an appointment
                                            </Button>
                                            <a href="https://play.google.com/store/apps/details?id=com.heroasis.in.app.android" target="_blank" >
                                            <img src={play}  width={200} style={{marginTop: 20}}/>
                                            </a>
                                        </div>
                                        </Zoom>
                                        </Grid>
                                    </Grid>
                                    </div>
                                </div>
                            ) : (
                                <div style={{width: '100%',backgroundImage :`url(${ImageApi+'/contents/'+item.image})`,backgroundRepeat: 'no-repeat',backgroundPosition: 'center',backgroundSize: '100% 100%',height : isMobile ? 750 : 600 }} >
                                    <div style={{height : isMobile ? 750 : 600 }} className={classes.leftContent}>
                                    <Zoom duration={3000}>
                                    <div style={{color : COLORS.white,width: isMobile ? '80%' : '50%'}} dangerouslySetInnerHTML={{__html: item.content}}>
                                            
                                    </div>
                                    <Button onClick={()=>setOpen(true)} size="large" color="primary" variant="contained" style={{marginTop: 20,backgroundColor: COLORS.primary,color: '#fff'}}>
                                        Make an appointment
                                    </Button>
                                    <a href="https://play.google.com/store/apps/details?id=com.heroasis.in.app.android" target="_blank" >
                                    <img src={play}  width={200} style={{marginTop: 20}}/>
                                    </a>
                                    </Zoom>
                                    </div>
                                </div>
                            )
                        } */}
                        <div style={{width: '100%',backgroundImage :`url(${ImageApi+'/contents/'+item.image})`,backgroundRepeat: 'no-repeat',backgroundPosition: 'center',backgroundSize: '100% 100%',height : isMobile ? 750 : 600 }} >
                                    <div style={{height : isMobile ? 750 : 600 }} className={classes.leftContent}>
                                    <Zoom duration={3000}>
                                    <div style={{color : COLORS.white,width: isMobile ? '80%' : '50%'}} dangerouslySetInnerHTML={{__html: item.content}}>
                                            
                                    </div>
                                    <Button onClick={()=>setOpen(true)} size="large" color="primary" variant="contained" style={{marginTop: 20,backgroundColor: COLORS.primary,color: '#fff'}}>
                                        Make an appointment
                                    </Button>
                                    <a href="https://play.google.com/store/apps/details?id=com.heroasis.in.app.android" target="_blank" >
                                    <img src={play}  width={200} style={{marginTop: 20}}/>
                                    </a>
                                    </Zoom>
                                    </div>
                        </div>
                        
                    </div>
                )
            }
                
                
                    
             
                
            </Slider>
            </div>
            <Container disableGutters maxWidth="xl" className={classes.testonomialsParent}>
                {/* <div>
                <WhyChoose  />
                </div> */}
        
                
                <AboutHome myref={myref} setMyref={setMyref} />
                
                <Physiologist ref={handleRef('Ambassadors')} />
                <Blogs ref={handleRef('Blogs')}/>
                <GalleryMIn ref={handleRef('Gallery')} />
                <Reviews />
                {/* <Blogs ref={handleRef('Blogs')}/> */}
                <ContactUs ref={handleRef('Contact Us')} /> 
                 <Steps />
            </Container>
        </Container>
        }
        
        </div>
    )
}

const useStyle = makeStyles({
    container: {
        margin: 0,
        padding: 0,
        backgroundColor: '#fffff'
    },
    homeInfo : {
        paddingTop: 0,
        marginTop: -70,
        marginBottom: 5,
        // backgroundImage : `url(${back1})`,
        // backgroundRepeat: 'no-repeat',
        //  backgroundPosition: 'center',
        //  backgroundSize : '100% 100%',
       
         overflow:'hidden',
         
         backgroundColor : '#000'

        
    },
    rightImage : {
        width: '80%'
    },
    leftContent : {
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        textAlign : 'center',
        backgroundColor : 'rgba(128,0,45,0.4)',
    },
    services : {
        backgroundColor: COLORS.lightGray,
        padding: 10,
        borderTopRightRadius: 100,
        borderTopLeftRadius: 100
       
    },
    serviceItem : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5
    },
    serviceImage : {
        width: 150,
        height: 150
    },
    testonomials : {
        textAlign: 'center',
        padding: 10,
        borderWidth: 1,
        borderColor: '#0c55c9'
    },
    testonomialsParent : {
        backgroundColor: COLORS.lightGray,
        paddingBottom : 50,
        
     
    },
   
    progImage: {
        width: '60%'
    },
    choose : {
        fontWeight  : 700,
      
    }
    
})


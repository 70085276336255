
import { useHistory } from "react-router"

export default function SignOut () {
    const history = useHistory()
    localStorage.removeItem('email')
    localStorage.removeItem('regitered')
    localStorage.removeItem('emailVarified')

    return history.replace('/')
}
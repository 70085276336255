import {
    Container,
    Paper,
    Grid,
    Typography,
    FormControl,
    TextField,
    Box,
    Button,
    useTheme,
    useMediaQuery
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import { Link } from 'react-router-dom'
import auth from '../../assets/auth.png'
import COLORS from '../../consts/colors'
import '../../fonts.css'
import {FaArrowRight} from 'react-icons/fa'
import {IconContext} from "react-icons";
import React from 'react'
import Loader from "react-js-loader";
import isEmail from 'validator/lib/isEmail';
import {Api} from '../../process/api'
import { useHistory } from 'react-router'
import SnakBarNew from '../../consts/notification'
export default function SignIn(){
    const history = useHistory()
    const classes = useStyle()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [data, setData] = React.useState({})
    const [error, setError] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [Massg, setMassg] = React.useState({})
    const onChange = (e)=>{
        setData({
            ...data,
            [e.target.name] : e.target.value
        })
    }

    const validate = ()=>{
        let error = {}
        let valid = true

        if(!data.email){
            valid = false
            error['email'] = true
            
        }else {
            if(isEmail(data.email)){
                error['not_email'] = false
            }else{
                valid = false
                error['not_email'] = true
            }
        }
        if(!data.pass){
            valid = false
            error['pass'] = true
        }
        

        setError(error)

        return valid
        
    }

    const onSubmit = ()=>{
        if(validate()){
       
        setLoading(true)
        fetch(Api+'auth',{
            method : 'POST',
            body : JSON.stringify(data)
        })
        .then(Response=>Response.json())
        .then(datas=>{
            console.log(datas)
            setLoading(false)
            if(datas.status === 201){
                localStorage.setItem('email', data.email)
                localStorage.setItem('regitered', true)
                localStorage.setItem('emailVarified',data.email_varify)
                history.replace('/Home')
            }else{
                setMassg({
                    open : true,
                    massg : datas.massg,
                    severity : 'error'
                })
            }
        })
        .catch(err=>{
            console.log(err)
            setMassg({
                massg : "Couldn't connect to the server" ,
                severity : 'error',
                open : true
            })
            setLoading(false)
        })
             
    }
    }
    return(
        <Container maxWidth="md">
            <Grid container component={Paper} elevation={1}  style={{
                backgroundColor : '#f7f7f7',
                borderRadius: 20,
            }}>
                <Grid item xs={12} sm={6} >
                    <img src={auth} className={classes.img} />
                </Grid>
                <Grid item xs={12} sm={6} style={{
                     borderRadius : 20,
                     padding : theme.spacing(isMobile ? 1 : 5),
                     backgroundColor : COLORS.white
                }} >
                <div className={classes.rightCon}>
                    <div className={classes.heading}>Sign in to Her Oasis</div>
                    <div className={classes.subHead}>
                        Don't have an account? <Link to="/SignUp" style={{textDecoration : 'none'}}>Sign Up</Link>
                    </div>
                    <Box className={classes.form}>
                    <div className={classes.TextField}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            className={classes.TextField}
                            fullWidth
                            name="email"
                            type="email"
                            value={data.email}
                            onChange={onChange}
                            error={error.email}
                            helperText={error.not_email ? <Typography style={{fontSize :12}} color="red">Please enter a valid email</Typography> : ''}
                        />
                    </div>
                    <div className={classes.TextField}>
                        <TextField
                            label="Password"
                            variant="outlined"
                            className={classes.TextField}
                            fullWidth
                            name="pass"
                            type="password"
                            value={data.pass}
                            error={error.pass}
                            onChange={onChange}
                        />
                    </div>
                    <div style={{display : 'flex',justifyContent: 'flex-end'}}>
                        <Link to="/ForgotPass" style={{textDecoration : 'none',color : '#000'}}>
                        <Button size="small">
                            Forgot password?
                        </Button>
                        </Link>
                    </div>
                    <div className={classes.TextField}>
                        <Button 
                            variant="contained" 
                            size="large"
                            fullWidth
                            onClick={onSubmit}
                            style={{
                                backgroundColor: COLORS.primary,
                                color: COLORS.white,
                                marginTop: 0,
                                '&:hover' : {
                                    backgroundColor: COLORS.primary,
                                    
                                }
                            }}
                        >    
                           {
                            loading ? (
                                
                                <Loader type="bubble-top" bgColor={"#FFFFFF"}  color={'#FFFFFF'} size={20} />
                               
                            ) : (
                                <IconContext.Provider value={{ color: COLORS.white, style:{marginLeft :10} }}>
                                <div>
                                    Sign In
                                    <FaArrowRight />
                                </div>
                                </IconContext.Provider>
                            )
                        }
                              
                        </Button> 
                    </div>  
                        
                        
                    </Box>
                </div>
                </Grid>
            </Grid>
            <SnakBarNew setMassg={setMassg} massg={Massg} />
        </Container>
    )
}


const useStyle = makeStyles(theme=>({
    img : {
        width: '60%',
        padding: '20%',
    },
    heading : {
        fontFamily : 'Raleway',
        paddingLeft : 20,
        fontSize : 26
    },
    subHead : {
        paddingLeft : 20,
        color : COLORS.lightBlack,
        fontFamily : 'Raleway',
    },
    TextField : {
        marginTop:  10,
        marginBottom : 10,
        marginLeft: 5
    },
    form : {
        padding : 15,
        
    },
    btnIcon:{
        color: COLORS.white,
        fill : 'white'
        
    },
    container : {
        border: '1px solid',
        borderColor: COLORS.gery,
        borderRadius: 10,
        backgroundColor : COLORS.white
    },
    rightCon : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
        height : '100%',
        borderRadius : 15,
        backgroundColor : COLORS.white
       
       
    }
}))
import React from "react";
import {
    Container,
    useTheme,
    useMediaQuery
} from '@mui/material'
import { Api,ImageApi } from "../process/api";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
export default function Gallery(){
    const [data, setData] = React.useState([])
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    React.useEffect(()=>{
        fetch(Api+'gallery')
        .then(res=>res.json())
        .then(data=>setData(data))
    },[])
    return (
        <Container>
        <ImageList variant="woven" cols={isMobile ? 2 : 4} gap={8}>
            {data.map((item) => (
                <ImageListItem key={item.image_name}>
                <img
                    src={`${ImageApi+'gallery/'+item.image_name}?w=161&fit=crop&auto=format`}
                    srcSet={`${ImageApi+'gallery/'+item.image_name}?w=161&fit=crop&auto=format&dpr=2 2x`}
                    alt='images'
                    loading="lazy"
                />
                </ImageListItem>
            ))}
        </ImageList>
        </Container>
    )
}
import React from 'react'
import {
    Container
} from '@mui/material'
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";
import {Api} from '../../process/api'
import {FcApproval,FcHighPriority} from 'react-icons/fc'
import { Typography } from '@material-ui/core';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function EmailVerification(){
    let query = useQuery();
    const [collected, setCollected] = React.useState({})
    React.useEffect(()=>{
        fetch(Api+'verifyEmail&token='+query.get('token'))
        .then(res=>res.json())
        .then(data=>setCollected(data))
        .catch(err=>console.log(err))
    },[])
    return(
        <Container
            style={{
                paddingTop: 100,
                display : 'flex',
                flexDirection : 'column',
                justifyContent : 'center',
                alignItems : 'center'

            }}
        >
        {
            collected.status === 201 ? (
                <div
                    style={{
                        display : 'flex',
                        flexDirection : 'column',
                        justifyContent : 'center',
                        alignItems : 'center'
        
                    }}
                >
                    <FcApproval  size={100}/>
                    <Typography>{collected.massg}</Typography>
                </div>
            ) : (
                <div
                    style={{
                        display : 'flex',
                        flexDirection : 'column',
                        justifyContent : 'center',
                        alignItems : 'center'
        
                    }}
                >
                    <FcHighPriority size={100}/>
                    <Typography>{collected.massg}</Typography>
                </div>
            )
        }
            

        </Container>
    )
}
import React from 'react';
import Home from './components/home';
import Appbar from './components/appbar';
import Fotter from './components/sub_home/fotter';
import {BrowserRouter,Link,Switch,Route} from 'react-router-dom'
import SignUp from './components/login/signup';
import SignIn from './components/login/signin';
import Appointment from './components/appointment/appoint';
import ContactUs from './components/contact';

import COLORS from './consts/colors';
import Physiologist from './components/physiologists';
import Account from './components/account';
import ScrollToTop from './process/scrolltotop';
import { useTheme,useMediaQuery,Container } from '@mui/material';
import { Fragment } from 'react';
import Dashboard from './components/dashboard/dashboard';
import SignOut from './components/login/signout';
import Privicy from './components/others/privicy';
import ForgotPass from './components/login/forgotPass';
import MyAppointments from './components/appointment/myAppointments';
import Profile from './components/account/profile';
import Terms from './components/others/terms';
import Refund from './components/others/refund';
import Gallery from './components/gallery';
import EmailVerification from './components/others/verification';
import Register from './components/login/register';
import AboutUs from './components/others/about';

function App() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [refs, setRefs] = React.useState()
    
  return (
    <BrowserRouter>
      <Appbar  setRefs={setRefs} />
      <Container maxWidth="xl" disableGutters style={{backgroundColor : COLORS.lightGray,padding: 0,paddingBottom: 100,margin: 0,paddingTop: 70,overflowX:'unset'}}>
    
      <ScrollToTop />
      <Switch>
        <Route exact path="/" render={()=><Home refs={refs} />} />
        <Route  path="/SignUp" component={SignUp} />
        <Route  path="/SignIn" component={SignIn} />
        <Route  path="/Appointment" component={Appointment} />
        <Route  path="/ContactUs" component={ContactUs} />
        <Route  path="/Physiologist" component={Physiologist} />
        <Route  path="/Account" component={Account} />
        <Route  path="/Home" component={Dashboard} />
        <Route  path="/SignOut" component={SignOut} />
        <Route  path="/Privicy" component={Privicy} />
        <Route  path="/ForgotPass" component={ForgotPass} />
        <Route  path="/MyAppointments" component={MyAppointments} />
        <Route  path="/Profile" component={Profile} />
        <Route  path="/Terms" component={Terms} />
        <Route  path="/Refund" component={Refund} />
        <Route  path="/Gallery" component={Gallery} />
        <Route  path="/EmailVerification" component={EmailVerification} />
        <Route  path="/Register" component={Register} />
        <Route  path="/About" component={AboutUs} />
      </Switch>
      
      </Container>
      <Fotter />
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import { Container,Paper,Grid,Typography } from "@mui/material"
import {FcMultipleDevices,FcSmartphoneTablet,FcMoneyTransfer,FcVoicePresentation} from 'react-icons/fc'
import { styled,makeStyles } from '@mui/styles';
import COLORS from "../../consts/colors";
import Reviews from "./reviews";
export default function WhyChoose(){
    const classes = useStyle()
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        // return () => observer.unobserve(domRef.current);
    }, []);

    return (
        <Container className={`fade-in-section-why-choose ${isVisible ? 'is-visible' : ''}`} ref={domRef} disableGutters maxWidth="xl" >
            
            <Paper elevation={0} style={{paddingTop: 0,padding: 15}} >
            <h2 style={{textAlign:'center',marginTop: 20}}>Why choose us?</h2>
            <hr style={{width : '40%',marginBottom: 50}} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} className={classes.testonomials}>
                    <FcVoicePresentation size={80} />
                    <Typography sx={{fontWeight: 600}}>Customer satisfaction</Typography>
                    <Typography variant="body2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</Typography>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.testonomials}>
                    <FcMultipleDevices size={80} />
                    <Typography sx={{fontWeight: 600}}>Digital Asitant</Typography>
                    <Typography variant="body2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</Typography>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.testonomials}>
                    <FcSmartphoneTablet size={80} />
                    <Typography sx={{fontWeight: 600}}>Easy to handle</Typography>
                    <Typography variant="body2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</Typography>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.testonomials}>
                    <FcMoneyTransfer size={80} />
                    <Typography sx={{fontWeight: 600}}>Low Cost</Typography>
                    <Typography variant="body2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry</Typography>
                </Grid>
            </Grid>
            </Paper>
            
        </Container>
    )
}


const useStyle = makeStyles({
  
    services : {
        backgroundColor: COLORS.lightGray,
        padding: 10,
        borderTopRightRadius: 100,
        borderTopLeftRadius: 100
       
    },
    serviceItem : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5
    },
    serviceImage : {
        width: 150,
        height: 150
    },
    testonomials : {
        textAlign: 'center',
        padding: 10,
        borderWidth: 1,
        borderColor: '#0c55c9'
    },
    testonomialsParent : {
        backgroundColor: COLORS.lightGray,
        marginTop: 20,
     
    },
   
    progImage: {
        width: '60%'
    },
    choose : {
        fontWeight  : 700,
      
    }
    
})